import {
  AcidType,
  AmmoniaType,
  FlowType,
  MagnesiumType,
  MetalSaltType,
  ProcessModelTarget,
  RawUserInput,
  ReactorModel,
} from './userInput';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { salesToolApiFetch, useSalesToolQuery } from '../../lib/sales-tool-api';
import { loadProcessModelFormInput, storeProcessModelFormInput } from './util';
import { CircularProgress, Container, Grid, Typography } from '@material-ui/core';
import ProcessModelForm from './ProcessModelForm';
import { useThrottle } from 'react-use';
import { RequiredProcessModelConstants } from './processModelConstants';

export interface PmrDataDependencies {
  mgFeedTypes: MagnesiumType[];
  metalSaltTypes: MetalSaltType[];
  processModelConstants: Record<string, number> & RequiredProcessModelConstants;
  reactorModels: ReactorModel[];
  ammoniaTypes: AmmoniaType[];
  acidTypes: AcidType[];
}

export const ProcessModelPage: FC = () => {
  const { isLoading, isError, data } = useSalesToolQuery('processModelForm', async () => {
    const {
      mgFeedTypes: mgTypes,
      metalSaltTypes,
      processModelConstants: constants,
      reactorModels: reactors,
      ammoniaTypes,
      acidTypes,
    } = await salesToolApiFetch<PmrDataDependencies>('/pmrDataDependencies');

    return {
      constants,
      reactors,
      mgTypes,
      metalSaltTypes,
      ammoniaTypes,
      acidTypes,
    };
  });

  const rawUserInput = useMemo((): RawUserInput => {
    const loaded = loadProcessModelFormInput();

    const def: RawUserInput = {
      flowType_1: FlowType.OTHER,
      flowType_2: FlowType.EMPTY,
      flowLabel_1: 'Feed Stream 1',
      flowLabel_2: 'Feed Stream 2',
      flowUnitWeb: 'gal/d',
      Q_2: 0,
      P_2: 0,
      N_2: 0,
      feedStreamForDosing: 'not_used',
      ww_MgCl2_Neat: 0.32,
      ww_NaOH_Neat: 0.5,
      solveTarget: ProcessModelTarget.PH_R,
      MetalSalt_Unit: 'dry ton',
      Alk_Consumption_Required: 'not_required',
      NaOH_Operating_Unit: 'dry ton',
      Dryer_Type: 'Electric',
      Ammonia_Unit: 'dry ton',
      Acid_Unit: 'gal',
      Product_Handling_Requested: 'Bagging (Silos)',
      NP_Add_YN: 'N',
    };

    if (!loaded) return def;

    return { ...def, ...loaded };
  }, []);

  // Keep a copy of the form state in localstorage, so we survive page reloads
  const [inputCopy, setInputCopy] = useState(rawUserInput);
  const throttledRawUserInput = useThrottle(inputCopy, 500);

  useEffect(() => {
    storeProcessModelFormInput(throttledRawUserInput);
  }, [throttledRawUserInput]);

  function renderChildren() {
    if (isLoading)
      return (
        <Grid container justify="center" alignContent="center">
          <CircularProgress disableShrink />
        </Grid>
      );

    if (isError)
      return (
        <Grid container justify="center" alignContent="center">
          <Typography variant="h6" gutterBottom color="error">
            There was an error contacting the server
          </Typography>
        </Grid>
      );

    return (
      <ProcessModelForm
        initialState={rawUserInput}
        onChange={setInputCopy}
        constants={data!.constants}
        reactors={data!.reactors}
        mgTypes={data!.mgTypes}
        metalSaltTypes={data!.metalSaltTypes}
        ammoniaTypes={data!.ammoniaTypes}
        acidTypes={data!.acidTypes}
      />
    );
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: 80 }}>
      {renderChildren()}
    </Container>
  );
};
