import React, { FC, memo } from 'react';
import { Fieldset } from '../Fieldset';
import { Grid, MenuItem } from '@material-ui/core';
import { numberOf, ProcessModelFormFieldsetProps } from './util';
import { FormPercentageInput, FormSelectInput } from './Inputs';
import { AmmoniaType, MagnesiumType } from './userInput';

type ChemicalDataFieldsProps = ProcessModelFormFieldsetProps<
  'ww_MgCl2_Neat' | 'ww_NaOH_Neat' | 'mgType' | 'ammonia' | 'NP_Add_YN'
> & {
  mgTypes: MagnesiumType[];
  ammoniaTypes: AmmoniaType[];
};

export const ChemicalDataFields: FC<ChemicalDataFieldsProps> = memo(
  ({ set, ww_MgCl2_Neat, ww_NaOH_Neat, mgType, mgTypes, ammonia, ammoniaTypes, NP_Add_YN }) => {
    return (
      <Fieldset title="Chemical Data">
        <Grid item xs={12} sm={6}>
          <FormPercentageInput
            label="Neat MgCl2 Concentration	(w/w)"
            value={ww_MgCl2_Neat ?? ''}
            onChange={(ev) => set('ww_MgCl2_Neat', numberOf(ev.target.value))}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormPercentageInput
            label="Neat NaOH Concentration(w/w)"
            value={ww_NaOH_Neat ?? ''}
            onChange={(ev) => set('ww_NaOH_Neat', numberOf(ev.target.value))}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormSelectInput
            label="Mg Feed Type"
            value={mgType?.id ?? ''}
            onChange={(ev) => {
              set(
                'mgType',
                mgTypes.find((f) => f.id === ev.target.value),
              );
            }}
          >
            {mgTypes.map((feed) => (
              <MenuItem key={feed.id} value={feed.id}>
                {feed.label}
              </MenuItem>
            ))}
          </FormSelectInput>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormSelectInput
            label="Ammonia Type"
            value={NP_Add_YN === 'Y' ? ammonia?.id ?? '' : ''}
            placeholder={NP_Add_YN === 'N' ? 'not_used' : undefined}
            onChange={(ev) => {
              set(
                'ammonia',
                ammoniaTypes.find((am) => am.id === ev.target.value),
              );
            }}
          >
            {NP_Add_YN === 'N' && <MenuItem value="not_used">Not Used</MenuItem>}
            {NP_Add_YN === 'Y' &&
              ammoniaTypes.map((am) => (
                <MenuItem key={am.id} value={am.id}>
                  {am.symbol_label}
                </MenuItem>
              ))}
          </FormSelectInput>
        </Grid>
      </Fieldset>
    );
  },
);
