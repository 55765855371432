import { FormControl, InputBaseProps, InputLabel, TextFieldProps } from '@material-ui/core';
import React, { FC, useMemo } from 'react';
import STInputBase from './STInputBase';
import uniqueId from 'lodash/uniqueId';

const STInput: FC<TextFieldProps> = (props) => {
  const { label, fullWidth = true, ...inputProps } = props;
  const uniqTextBox = useMemo(() => uniqueId('input-'), []);

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel shrink htmlFor={uniqTextBox} color="primary" disableAnimation>
        {label}
      </InputLabel>
      <STInputBase id={uniqTextBox} {...(inputProps as InputBaseProps)} />
    </FormControl>
  );
};

export default STInput;
