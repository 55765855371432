import { ReactorModel } from './userInput';
import React, { FC, memo } from 'react';
import { Fieldset } from '../Fieldset';
import { Grid, MenuItem, TableCell } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import { FormNumberInput, FormSelectInput } from './Inputs';
import { numberOf, ProcessModelFormFieldsetProps } from './util';
import {
  CalculatedNumber,
  CalculatedPercentage,
  CalculatedValue,
  fmtNumber,
} from './CalculatedValues';
import { Alert } from '@material-ui/lab';

type ReactorModelFieldsProps = ProcessModelFormFieldsetProps<
  'reactorModel' | 'Qty_reactor_user',
  | 'Pearl_Reactor_Suggestion'
  | 'Qty_Pearl_Reactor_Suggestion'
  | 'Usage_Pearl_Reactor_Suggestion'
  | 'Pearl_Fx_Reactor_Suggestion'
  | 'Qty_Pearl_Fx_Reactor_Suggestion'
  | 'Usage_Pearl_Fx_Reactor_Suggestion'
  | 'Qty_reactor_suggested'
  | 'Usage_reactor'
  | 'P_mgL_In'
  | 'Pearl_Fx_Max_Concentration'
> & {
  reactorModels: ReactorModel[];
};

export const ReactorModelFields: FC<ReactorModelFieldsProps> = memo(
  ({
    set,
    reactorModel,
    Qty_reactor_user,
    Qty_reactor_suggested,
    Usage_reactor,
    Pearl_Reactor_Suggestion,
    Qty_Pearl_Reactor_Suggestion,
    Usage_Pearl_Reactor_Suggestion,
    Pearl_Fx_Reactor_Suggestion,
    Qty_Pearl_Fx_Reactor_Suggestion,
    Usage_Pearl_Fx_Reactor_Suggestion,
    reactorModels,
    P_mgL_In,
    Pearl_Fx_Max_Concentration,
  }) => {
    return (
      <Fieldset title="Reactor Model">
        <Grid item xs>
          <TableContainer>
            <Table size="small" style={{ tableLayout: 'fixed', minWidth: 600 }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '30%' }}>Type</TableCell>
                  <TableCell style={{ width: '30%' }}>Model</TableCell>
                  <TableCell style={{ width: '15%' }}>Quantity</TableCell>
                  <TableCell>Capacity Usage</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Pearl Reactor Suggestion</TableCell>
                  <TableCell>
                    <CalculatedValue>{Pearl_Reactor_Suggestion}</CalculatedValue>
                  </TableCell>
                  <TableCell>
                    <CalculatedNumber value={Qty_Pearl_Reactor_Suggestion} />
                  </TableCell>
                  <TableCell>
                    <CalculatedPercentage value={Usage_Pearl_Reactor_Suggestion} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Pearl Fx Reactor Suggestion</TableCell>
                  <TableCell>
                    <CalculatedValue>{Pearl_Fx_Reactor_Suggestion}</CalculatedValue>
                  </TableCell>
                  <TableCell>
                    <CalculatedNumber value={Qty_Pearl_Fx_Reactor_Suggestion} />
                  </TableCell>
                  <TableCell>
                    <CalculatedPercentage value={Usage_Pearl_Fx_Reactor_Suggestion} />
                  </TableCell>
                </TableRow>
                {P_mgL_In > Pearl_Fx_Max_Concentration ? (
                  <TableRow>
                    <TableCell />
                    <TableCell colSpan={2}>
                      <Alert severity="warning">
                        Combined P concentration ({fmtNumber(P_mgL_In)}) is greater than{' '}
                        {fmtNumber(Pearl_Fx_Max_Concentration)}. Selecting a Pearl Fx reactor not
                        recommended.
                      </Alert>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                ) : null}
                <TableRow>
                  <TableCell>Selected Reactor</TableCell>
                  <TableCell>
                    <FormSelectInput
                      value={reactorModel?.name ?? ''}
                      onChange={(ev) => {
                        set(
                          'reactorModel',
                          reactorModels.find((r) => r.name === ev.target.value),
                        );
                      }}
                    >
                      {reactorModels.map((reactor) => (
                        <MenuItem key={reactor.name} value={reactor.name}>
                          {reactor.name}
                        </MenuItem>
                      ))}
                    </FormSelectInput>
                  </TableCell>
                  <TableCell>
                    <FormNumberInput
                      value={Qty_reactor_user ?? ''}
                      placeholder={Qty_reactor_suggested?.toString() ?? ''}
                      onChange={(ev) => set('Qty_reactor_user', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <CalculatedPercentage value={Usage_reactor} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Fieldset>
    );
  },
);
