import React, { FC, useMemo } from 'react';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { ProcessModelRun } from '../../../api/src/process-model-runs/process-model-run.model';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useAuthContext } from './AuthContext';
import downloadS3File from '../lib/download-file';
import { storeProcessModelFormInput } from './ProcessModel/util';
import { useHistory } from 'react-router';
import useUsers from '../base/useUsers';
import { ToastType, useToastContext } from '../base/ToastProvider';
import DownloadIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import {
  labelOfProcessModelRunStatus,
  ProcessModelRunStatus,
  processModelRunStatuses,
} from '../lib/process-model-run-util';
import STButton from '../base/STButton';
import STInput from '../base/STInput';
import { useMutation, useQueryClient } from 'react-query';
import { Auth } from 'aws-amplify';
import { salesToolApiFetch } from '../lib/sales-tool-api';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'relative',
  },
  table: {
    minWidth: 700,
  },
  loader: {
    top: '50%',
    left: '50%',
    position: 'absolute',
  },
});

const ProcessModelRunsTable: FC<{
  rows: ProcessModelRun[];
  isLoading: boolean;
}> = ({ rows, isLoading }) => {
  const history = useHistory();
  const { isAdmin } = useAuthContext();
  const classes = useStyles();
  const { getUserById, isLoading: usersLoading } = useUsers();
  const { addToast } = useToastContext();

  const dateFormatter = useMemo(
    () =>
      new Intl.DateTimeFormat('en-US', {
        dateStyle: 'medium',
      } as Intl.DateTimeFormatOptions),
    [],
  );

  const styleHeader = { width: '15%' };
  const alignmentCell = isAdmin ? 'right' : 'left';

  return (
    <div className={classes.root}>
      {isLoading || (usersLoading && <CircularProgress disableShrink className={classes.loader} />)}
      <TableContainer component={Paper}>
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              {isAdmin && <StyledTableCell style={{ width: '22%' }}>Created By</StyledTableCell>}
              <StyledTableCell style={styleHeader} align={alignmentCell}>
                Plant Name
              </StyledTableCell>
              <StyledTableCell style={styleHeader} align="right">
                Plant Owner
              </StyledTableCell>
              <StyledTableCell style={styleHeader} align="right">
                Created On
              </StyledTableCell>
              <StyledTableCell style={styleHeader} align="right">
                Status
              </StyledTableCell>
              <StyledTableCell style={styleHeader} align="right">
                Actions
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                {isAdmin && (
                  <StyledTableCell>{getUserById(row.createdBy.id)?.name}</StyledTableCell>
                )}
                <StyledTableCell align={alignmentCell}>{row.plantName}</StyledTableCell>
                <StyledTableCell align="right">{row.plantOwner}</StyledTableCell>
                <StyledTableCell align="right">
                  {dateFormatter.format(new Date(row.createdAt))}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {labelOfProcessModelRunStatus(row.status as ProcessModelRunStatus)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    role="button"
                    onClick={(ev) => {
                      ev.preventDefault();
                      downloadS3File(row.s3Key).catch((err) =>
                        addToast(err.message, ToastType.Error),
                      );
                    }}
                    title="Download"
                  >
                    <DownloadIcon />
                  </a>{' '}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    role="button"
                    href="#"
                    onClick={(ev) => {
                      ev.preventDefault();
                      storeProcessModelFormInput({
                        ...row.userInput,
                        plantName: row.plantName,
                        plantOwner: row.plantOwner,
                      });
                      history.push('/process-model');
                    }}
                  >
                    <FileCopyIcon />
                  </a>
                  <ChangeStatusAction pmr={row} />
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

interface ChangeStatusActionProps {
  pmr: ProcessModelRun;
}

const ChangeStatusAction = ({ pmr }: ChangeStatusActionProps) => {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        role="button"
        href="#"
        onClick={() => {
          setOpen(true);
        }}
        title="Change Status"
      >
        <EditAttributesIcon />
      </a>
      {open && (
        <UpdatePmrDialog
          pmr={pmr}
          open={open}
          setOpen={setOpen}
          onUpdate={() => {
            queryClient.invalidateQueries('processModelRuns');
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

interface UpdatePmrDialogProps {
  pmr: ProcessModelRun;
  open: boolean;
  setOpen: (open: boolean) => void;
  onUpdate: () => void;
}

const UpdatePmrDialog = ({ pmr, open, setOpen, onUpdate }: UpdatePmrDialogProps) => {
  const { addToast, clearToast } = useToastContext();
  const [status, setStatus] = React.useState(pmr.status);

  const { mutate, isLoading } = useMutation(async () => {
    try {
      clearToast();
      const credentials = await Auth.currentUserCredentials();

      await salesToolApiFetch('/processModelRuns', {
        method: 'PUT',
        body: JSON.stringify({
          id: pmr.id,
          status,
          cognitoId: credentials.identityId,
        }),
      });

      addToast('Status changed successfully');
      onUpdate();
    } catch (err) {
      addToast(`Update error: ${err.message}`, ToastType.Error);
      return null;
    }
  });

  const handleClose = () => {
    if (!isLoading) setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Change status</DialogTitle>
      <DialogContent>
        <Select
          value={status}
          onChange={(ev) => {
            setStatus(ev.target.value as string);
          }}
          input={<STInput label="Status" />}
        >
          {processModelRunStatuses().map((status) => (
            <MenuItem key={status} value={status}>
              {labelOfProcessModelRunStatus(status)}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <STButton onClick={handleClose} color="default" disabled={isLoading}>
          Cancel
        </STButton>
        <STButton onClick={() => mutate()} disabled={isLoading}>
          Update
        </STButton>
        {isLoading && <CircularProgress size="1.3rem" />}
      </DialogActions>
    </Dialog>
  );
};

export default ProcessModelRunsTable;
