import React, { useState, useCallback, createContext, useContext, useEffect } from 'react';
import { useLocation } from 'react-router';

export enum ToastType {
  Error = 'ERROR',
  Normal = 'NORMAL',
}

interface ToastProvider {
  message: string | null;
  type: ToastType;
  addToast: (message: string, type?: ToastType) => void;
  clearToast: () => void;
}

const ToastContext = createContext<ToastProvider>({
  message: null,
  type: ToastType.Normal,
  addToast: () => {},
  clearToast: () => {},
});

const ToastContextProvider = ({ children }: any) => {
  const { pathname } = useLocation();
  const [message, setMessage] = useState(null);
  const [type, setType] = useState<ToastType>(ToastType.Normal);

  useEffect(() => {
    setMessage(null);
  }, [pathname]);

  const clearToast = useCallback(() => {
    setMessage(null);
  }, []);

  const addToast = useCallback((message, type) => {
    setType(type);
    setMessage(message);
  }, []);

  const contextValue = {
    message,
    type,
    addToast,
    clearToast,
  };

  return <ToastContext.Provider value={contextValue}>{children}</ToastContext.Provider>;
};

export default ToastContextProvider;

export const useToastContext = () => useContext(ToastContext);
