import { ProcessModelComputedInput, RawUserInput, SecondaryModelComputedInput } from './userInput';

export type ProcessModelFormFieldsetProps<
  T extends keyof RawUserInput,
  U extends keyof ProcessModelComputedInput = never,
> = { set: UpdateRawInputFnTpl<T> } & Pick<RawUserInput, T> & Pick<ProcessModelComputedInput, U>;

export type SecondaryModelFormFieldsetProps<
  T extends keyof RawUserInput,
  U extends keyof SecondaryModelComputedInput = never,
  R extends keyof ProcessModelComputedInput = never,
> = { set: UpdateRawInputFnTpl<T> } & Pick<RawUserInput, T> &
  Pick<SecondaryModelComputedInput, U> &
  Pick<ProcessModelComputedInput, R>;

export type UpdateRawInputFnTpl<T extends keyof RawUserInput> = <U extends T>(
  key: U,
  value: RawUserInput[U],
) => void;

export function numberOf<T = undefined>(val: string, defaultIfNaN?: T) {
  const number = Number.parseFloat(val);
  return Number.isFinite(number) ? number : defaultIfNaN;
}

const localStorageKey = 'ostara_ProcessModelFormInput';

export function storeProcessModelFormInput(form: RawUserInput) {
  try {
    localStorage.setItem(localStorageKey, JSON.stringify(form));
  } catch (e) {
    console.error(e);
  }
}

export function loadProcessModelFormInput(): RawUserInput | null {
  try {
    const formStr = localStorage.getItem(localStorageKey);
    return formStr ? JSON.parse(formStr) : null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function clearProcessModelFormInput() {
  try {
    localStorage.removeItem(localStorageKey);
  } catch (e) {
    console.error(e);
  }
}
