import { createMuiTheme } from '@material-ui/core';
import { AlertClassKey, AutocompleteClassKey } from '@material-ui/lab';

const palette1 = '#122F51';
const palette2 = '#8C004C';
const palette3 = '#7D7D7D';
export const primaryColor = palette1;
export const secondaryColor = palette2;
export const amplifyLightGrey = '#c4c4c4'; // Get it from https://docs.amplify.aws/ui/customization/theming/q/framework/react
const fontFamily = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  '"Fira Sans"',
  '"Droid Sans"',
  '"Helvetica Neue"',
  'sans-serif',
].join(',');

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
    MuiAutocomplete: AutocompleteClassKey;
  }
}

export const theme = createMuiTheme({
  typography: {
    fontFamily: fontFamily,
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  overrides: {
    MuiAlert: {
      standardSuccess: {
        color: 'white',
        backgroundColor: palette3,
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        padding: '5px !important',
      },
    },
    MuiInputLabel: {
      outlined: {
        pointerEvents: 'auto',
      },
    },
    MuiFormLabel: {
      root: {
        color: primaryColor,
        transformOrigin: 'top left',
        transform: 'translate(0, 1.5px) scale(0.85) !important',
      },
    },
    MuiSelect: {
      select: {
        paddingTop: '16px !important',
        paddingBottom: '13px !important',
        '&:focus': {
          paddingTop: '15px !important',
          paddingBottom: '12px !important',
        },
      },
    },
    MuiInputBase: {
      root: {
        marginTop: 24,
        borderRadius: 3,
        marginBottom: 16,
        '& fieldset legend': {
          display: 'none',
        },
      },
      adornedEnd: {
        marginTop: 28,
      },
      input: {
        fontSize: '14px',
        fontFamily: fontFamily, // This is necessary since there is not a clean way to send this family on date picker
        '&.MuiOutlinedInput-input': {
          padding: '15px 26px 14px 14px',
        },
      },
    },
  },
});

theme.typography = {
  ...theme.typography,
  h2: {
    ...theme.typography.h2,
    [theme.breakpoints.up('xs')]: {
      fontSize: '2rem',
    },
  },
  h3: {
    ...theme.typography.h3,
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.8rem',
    },
  },
  h4: {
    ...theme.typography.h4,
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.5rem',
    },
  },
};

export const amplifyTheming = {
  '--container-height': 'auto',
  '--amplify-font-family': fontFamily,
  '--amplify-primary-color': secondaryColor,
  '--amplify-primary-tint': secondaryColor,
  '--amplify-secondary-tint': secondaryColor,
  '--amplify-primary-shade': secondaryColor,
  '--amplify-secondary-color': primaryColor,
};
