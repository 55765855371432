import React from 'react';
import { Route, Switch } from 'react-router';
import ProcessModelRuns from './ProcessModelRuns';
import { ProcessModelPage } from './ProcessModel/ProcessModelPage';

export default function SalesRepDashboard() {
  return (
    <Switch>
      <Route exact path="/" component={ProcessModelRuns} />
      <Route exact path="/process-model" component={ProcessModelPage} />
    </Switch>
  );
}
