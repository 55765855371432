import React, { FC, useState } from 'react';
import {
  Checkbox,
  Container,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import STInput from '../base/STInput';
import STButton from '../base/STButton';
import STDatePicker from '../base/STDatePicker';
import ProcessModelRunsTable from './ProcessModelRunsTable';
import Pagination from '@material-ui/lab/Pagination/Pagination';
import { salesToolApiFetch, useSalesToolQuery } from '../lib/sales-tool-api';
import { ProcessModelRun } from '../../../api/src/process-model-runs/process-model-run.model';
import {
  ArrayParam,
  DateParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import STUsersSelector from '../base/STUsersSelector';
import { PaginationResultInterface } from '../../../api/src/paginate/pagination-results.interface';
import useDebounce from 'react-use/lib/useDebounce';
import CircularProgress from '@material-ui/core/CircularProgress';
import ProcessModelRunsCaption from './ProcessModelRunsCaption';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { Link } from 'react-router-dom';
import { useAuthContext } from './AuthContext';
import { User } from '../base/User';
import { clearProcessModelFormInput } from './ProcessModel/util';
import {
  labelOfProcessModelRunStatus,
  ProcessModelRunStatus,
  processModelRunStatuses,
} from '../lib/process-model-run-util';
import uniq from 'lodash/uniq';

const ProcessModelRuns: FC<{ width: Breakpoint }> = ({ width }) => {
  const { isAdmin } = useAuthContext();
  const [modelProcessRuns, setModelProcessRuns] = useState<ProcessModelRun[]>();
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [rawQueryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    user: withDefault(StringParam, ''),
    afterDate: withDefault(DateParam, null),
    beforeDate: withDefault(DateParam, null),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 20),
    status: withDefault(ArrayParam, [ProcessModelRunStatus.ACTIVE as string]),
  });

  const selectedStatuses = uniq(
    rawQueryParams.status.map(
      (statusStr) =>
        ProcessModelRunStatus[statusStr as keyof typeof ProcessModelRunStatus] ??
        ProcessModelRunStatus.ACTIVE,
    ),
  );
  const selectedStatusesJoined = selectedStatuses.join(',');

  useDebounce(() => setDebouncedSearch(rawQueryParams.search), 200, [rawQueryParams.search]);

  // remove title since we are using debounced
  // remove status since it's an array
  const { search, status, ...restParams } = rawQueryParams;
  const { isLoading, data, error } = useSalesToolQuery<PaginationResultInterface<ProcessModelRun>>(
    [
      'processModelRuns',
      {
        debouncedSearch,
        selectedStatusesJoined,
        ...restParams,
      },
    ],
    () => {
      return salesToolApiFetch(
        '/processModelRuns?' +
          new URLSearchParams({
            page: rawQueryParams.page!.toString(),
            limit: rawQueryParams.limit!.toString(),
            search: debouncedSearch.trim(),
            user: rawQueryParams.user,
            afterDate: rawQueryParams.afterDate?.toISOString() || '',
            beforeDate: rawQueryParams.beforeDate?.toISOString() || '',
            status: selectedStatusesJoined,
          }),
      );
    },
    {
      onSuccess: (newData: PaginationResultInterface<ProcessModelRun>) => {
        setModelProcessRuns(newData.results);
      },
    },
  );

  const pageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setQueryParams({ page: value });
  };

  const renderAreaTable = () =>
    modelProcessRuns?.length ? (
      <>
        <ProcessModelRunsCaption total={data?.total} selectedUser={rawQueryParams.user} />
        <ProcessModelRunsTable rows={modelProcessRuns} isLoading={isLoading} />
        {data && data.pages > 1 && (
          <div style={{ margin: '40px 0' }}>
            <Pagination
              count={data.pages}
              page={rawQueryParams.page as number}
              onChange={pageChange}
              variant="outlined"
              shape="rounded"
              color="primary"
              size={isWidthUp('sm', width) ? 'medium' : 'small'}
            />
          </div>
        )}
      </>
    ) : !isLoading ? (
      <Typography variant="h6" gutterBottom style={{ marginTop: '40px' }}>
        Your search didn't return any results.
      </Typography>
    ) : (
      <Grid container justify="center" style={{ marginTop: 80 }}>
        <CircularProgress disableShrink />
      </Grid>
    );

  const renderAreaResults = () => (
    <Grid container spacing={1} justify="center" alignItems="center" direction="row">
      {!isLoading && error ? (
        <Typography variant="h6" gutterBottom color="error" style={{ marginTop: '40px' }}>
          There was an error trying to get results
        </Typography>
      ) : (
        renderAreaTable()
      )}
    </Grid>
  );

  return (
    <Container maxWidth="md">
      <Grid container spacing={1} direction="row">
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            Process Model Runs
            <STButton
              component={Link}
              to="/process-model"
              onClick={() => clearProcessModelFormInput()}
              style={{ marginLeft: isWidthUp('sm', width) ? '20px' : '5px' }}
            >
              New
            </STButton>
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <STInput
            fullWidth
            label="Search"
            placeholder="Plant Name/Owner"
            autoComplete="search"
            value={rawQueryParams.search}
            onChange={(e) => setQueryParams({ search: e.target.value, page: 1 })}
          />
        </Grid>
        {isAdmin && (
          <Grid item xs={12} md={3}>
            <STUsersSelector
              label="Created By"
              value={rawQueryParams.user}
              onChange={(_: unknown, newValue: User) =>
                setQueryParams({ user: newValue?.id, page: 1 })
              }
            />
          </Grid>
        )}
        <Grid item xs={12} md={3}>
          <STDatePicker
            fullWidth
            label="Created After"
            value={rawQueryParams.afterDate}
            onChange={(dateAfter) => {
              if (
                !!dateAfter &&
                rawQueryParams.beforeDate &&
                dateAfter >= rawQueryParams.beforeDate
              ) {
                setQueryParams({ beforeDate: null });
              }
              setQueryParams({ afterDate: dateAfter, page: 1 });
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <STDatePicker
            fullWidth
            label="Created Before"
            value={rawQueryParams.beforeDate}
            onChange={(dateBefore) => {
              if (
                !!dateBefore &&
                rawQueryParams.afterDate &&
                dateBefore <= rawQueryParams.afterDate
              ) {
                setQueryParams({ afterDate: null });
              }
              setQueryParams({ beforeDate: dateBefore, page: 1 });
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Select
            multiple
            value={selectedStatuses}
            onChange={(ev) => {
              setQueryParams({ status: ev.target.value as string[] });
            }}
            input={<STInput label="Status" />}
            renderValue={(selected) =>
              (selected as ProcessModelRunStatus[]).length === processModelRunStatuses().length
                ? 'All'
                : selectedStatuses.map(labelOfProcessModelRunStatus).join(', ')
            }
            MenuProps={{
              PaperProps: {
                style: {
                  width: 250,
                },
              },
            }}
          >
            {processModelRunStatuses().map((status) => (
              <MenuItem key={status} value={status}>
                <Checkbox checked={selectedStatuses.includes(status)} />
                <ListItemText primary={labelOfProcessModelRunStatus(status)} />
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      {renderAreaResults()}
    </Container>
  );
};

export default withWidth()(ProcessModelRuns);
