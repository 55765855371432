export async function jsonFetch<T extends unknown>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<T> {
  const ret = await fetch(input, {
    ...init,
    headers: {
      'Content-Type': 'application/json',
      ...init?.headers,
    },
  });

  if (!ret.ok) throw new Error(`Error ${ret.status}: ${ret.statusText}`);

  return ret.json();
}
