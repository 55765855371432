import React from 'react';
import ReactDOM from 'react-dom';
import SalesTool from './SalesTool';
import Amplify from 'aws-amplify';
import awsExports from './aws-exports';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@material-ui/core';
import './assets/scss/index.scss';
import { amplifyTheming, theme } from './base/theme';
import { PushReplaceHistory, QueryParamProvider } from 'use-query-params';

export const IDENTITY_PROVIDER = 'OstaraOffice365';
const env =
  process.env.NODE_ENV !== 'production' || process.env.REACT_APP_USER_BRANCH !== 'production'
    ? 'dev'
    : 'prod';
const auth = {
  oauth: {
    domain: `ostara-salestool-${env}.auth.${awsExports.aws_cognito_region}.amazoncognito.com`,
    scope: ['aws.cognito.signin.user.admin', 'phone', 'email', 'profile', 'openid'],
    redirectSignIn: window.location.origin,
    redirectSignOut: window.location.origin,
    responseType: 'token',
    client_id: awsExports.aws_user_pools_web_client_id,
    identity_provider: IDENTITY_PROVIDER,
  },
};

Amplify.configure({ ...awsExports, ...auth });
const history = createBrowserHistory();
const queryClient = new QueryClient();

ReactDOM.render(
  <Router history={history}>
    <QueryParamProvider history={history as unknown as PushReplaceHistory}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <SalesTool amplifyTheming={amplifyTheming} />
        </ThemeProvider>
      </QueryClientProvider>
    </QueryParamProvider>
  </Router>,
  document.getElementById('root'),
);
