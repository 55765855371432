import React, { FormEvent, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useAuthContext } from './AuthContext';
import { Container, Grid, Typography } from '@material-ui/core';
import STInput from '../base/STInput';
import STButton from '../base/STButton';
import { ToastType, useToastContext } from '../base/ToastProvider';

export default function UpdatePassword() {
  const { user } = useAuthContext();
  const [currentPw, setCurrentPw] = useState<string>('');
  const [newPw, setNewPw] = useState<string>('');
  const [confirmWewPw, setConfirmNewPw] = useState<string>('');
  const { addToast, clearToast } = useToastContext();

  const handleSuccess = (data: unknown) => {
    if (data === 'SUCCESS') {
      addToast('Your password has been successfully changed');
      setCurrentPw('');
      setNewPw('');
      setConfirmNewPw('');
    } else {
      addToast('There was a problem changing your password', ToastType.Error);
    }
  };

  const handleError = (err: { message: string }) => {
    addToast(err.message, ToastType.Error);
  };
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    clearToast();
    if (newPw === confirmWewPw) {
      Auth.changePassword(user, currentPw, newPw).then(handleSuccess).catch(handleError);
    } else {
      addToast('New password should match with the confirmation password', ToastType.Error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Grid item xs={12} spacing={3}>
        <Typography variant="h5" gutterBottom>
          Change Password
        </Typography>
      </Grid>

      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Grid item xs={12} spacing={3}>
          <STInput
            required
            fullWidth
            type="password"
            label="Enter current password"
            autoComplete="current-password"
            value={currentPw}
            onChange={(event) => setCurrentPw(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} spacing={3}>
          <STInput
            required
            fullWidth
            type="password"
            label="Enter new password"
            autoComplete="new-password"
            value={newPw}
            onChange={(event) => setNewPw(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} spacing={3}>
          <STInput
            required
            fullWidth
            type="password"
            label="Confirm new password"
            autoComplete="confirm-new-password"
            value={confirmWewPw}
            onChange={(event) => setConfirmNewPw(event.target.value)}
          />
        </Grid>

        <STButton type="submit">save</STButton>
      </form>
    </Container>
  );
}
