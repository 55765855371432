import React, { ComponentProps, FC, useMemo } from 'react';
import uniqueId from 'lodash/uniqueId';
import {
  createStyles,
  FormControl,
  InputAdornment,
  InputBaseProps,
  InputLabel,
  Select,
  TextFieldProps,
  Theme,
  withStyles,
} from '@material-ui/core';
import STInputBase from '../../base/STInputBase';
import { makeStyles } from '@material-ui/core/styles';
import { numberOf } from './util';
import { fmtNumber } from './CalculatedValues';

export const TextInputBase = withStyles((theme: Theme) =>
  createStyles({
    input: {
      transition: theme.transitions.create(['border-color', 'box-shadow', 'background-color']),
      fontSize: 16,
      padding: '10px 18px 10px !important',
      '&:focus': {
        borderWidth: 2,
        padding: '9px 17px 9px !important',
      },
      background: '#FFF3C1',
      color: '#000',
    },
  }),
)(STInputBase);

const useStyles = makeStyles(() => ({
  computed: {
    background: '#DCE6F1',
    '&::placeholder': {
      opacity: 0.7,
    },
  },
}));

export const FormTextInput: FC<TextFieldProps> = (props) => {
  const classes = useStyles();
  const { label, fullWidth = true, InputProps, ...inputProps } = props;
  const uniqTextBox = useMemo(() => uniqueId('input-'), []);

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel shrink htmlFor={uniqTextBox} color="primary" disableAnimation>
        {label}
      </InputLabel>
      <TextInputBase
        id={uniqTextBox}
        required={!props.placeholder}
        classes={{
          input: `${
            props.placeholder && !props.value && props.value !== 0 ? classes.computed : ''
          }`,
        }}
        {...InputProps}
        {...(inputProps as InputBaseProps)}
      />
    </FormControl>
  );
};

export const FormNumberInput: FC<ComponentProps<typeof FormTextInput>> = (props) => (
  <FormTextInput
    type="number"
    {...props}
    inputProps={{ min: 0, step: 'any', ...props.inputProps }}
  />
);

export const FormPriceInput: FC<
  ComponentProps<typeof FormTextInput> & { currency: string; unit?: string }
> = ({ currency, unit, ...props }) => (
  <FormTextInput
    type="number"
    {...props}
    inputProps={{ min: 0, step: 'any', ...props.inputProps }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          {currency}
          {unit ? `/${unit}` : ''}
        </InputAdornment>
      ),
    }}
  />
);

export const FormPercentageInput: FC<
  Omit<ComponentProps<typeof FormTextInput>, 'placeholder'> & {
    value: number | string;
    placeholder?: number | string | null;
  }
> = ({ value, onChange, placeholder, ...props }) => {
  return (
    <FormTextInput
      type="number"
      {...props}
      placeholder={
        placeholder !== undefined && placeholder !== null
          ? fmtNumber(
              (typeof placeholder === 'number' ? placeholder : Number.parseInt(placeholder, 10)) *
                100,
            )
          : undefined
      }
      value={
        value !== ''
          ? fmtNumber((typeof value === 'number' ? value : Number.parseInt(value, 10)) * 100)
          : ''
      }
      onChange={(ev) => {
        const valueNum = numberOf(ev.target.value);
        ev.target.value =
          typeof valueNum === 'number' ? (valueNum / 100).toString() : ev.target.value;
        onChange?.(ev);
      }}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
      inputProps={{
        min: 0,
        max: 100,
        step: 'any',
        ...props.inputProps,
      }}
    />
  );
};

export const FormSelectInput: FC<ComponentProps<typeof Select>> = (props) => {
  const { label, fullWidth = true, children, placeholder, value, ...restProps } = props;
  const uniqTextBox = uniqueId('input-');
  const classes = useStyles();
  const isPlaceholder = value === '' && props.placeholder;

  return (
    <FormControl fullWidth={fullWidth}>
      {label && (
        <InputLabel
          id={`${uniqTextBox}label`}
          shrink
          htmlFor={uniqTextBox}
          color="primary"
          disableAnimation
        >
          {label}
        </InputLabel>
      )}
      <Select
        labelId={`${uniqTextBox}label`}
        id={uniqTextBox}
        input={<TextInputBase />}
        required={!props.placeholder}
        value={isPlaceholder ? props.placeholder : value}
        classes={{
          select: `${isPlaceholder ? classes.computed : ''}`,
        }}
        {...restProps}
      >
        {children}
      </Select>
    </FormControl>
  );
};
