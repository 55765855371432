import React, { ComponentProps, FC, useMemo, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import STButton from '../base/STButton';

export const Fieldset: FC<{ title: string } & ComponentProps<typeof Grid>> = ({
  title,
  children,
  ...props
}) => {
  const [visible, setVisible] = useState(true);

  const style = useMemo(() => (visible ? {} : { display: 'none' }), [visible]);

  return (
    <div style={{ marginBottom: 32 }}>
      <Typography variant="h3" gutterBottom>
        {title}{' '}
        <STButton
          color="default"
          size="small"
          variant="text"
          style={{ opacity: 0.7 }}
          onClick={() => setVisible(!visible)}
        >
          {visible ? '▼' : '▲'}
        </STButton>
      </Typography>
      <Grid container spacing={4} {...props} style={style}>
        {children}
      </Grid>
    </div>
  );
};
