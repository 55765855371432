import React, { FC, memo } from 'react';
import { Fieldset } from '../Fieldset';
import { Grid, MenuItem, TableCell } from '@material-ui/core';
import { numberOf, SecondaryModelFormFieldsetProps } from './util';
import { FormNumberInput, FormPriceInput, FormSelectInput } from './Inputs';
import { useVolumeSelectOptions } from './useVolumeSelectOptions';
import { AcidType } from './userInput';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';

type OperatingCostsFieldProps = SecondaryModelFormFieldsetProps<
  | 'currency'
  | 'flowUnitWeb'
  | 'mgType'
  | 'Operating_Labour_Cost'
  | 'Electricity_Cost'
  | 'NaOH_Operating_Cost'
  | 'NaOH_Operating_Unit'
  | 'Dryer_Type'
  | 'Qty_dryer'
  | 'Natural_Gas_Cost'
  | 'Hot_Water_Cost'
  | 'Mg_Operating_Unit'
  | 'Mg_Operating_Cost'
  | 'ammonia'
  | 'Ammonia_Unit'
  | 'Ammonia_Cost'
  | 'acid'
  | 'Acid_Unit'
  | 'Acid_Cost'
  | 'Lab_Hatch_Cost'
  | 'Pallets_Unit_Cost'
  | 'Product_Handling_Requested'
  | 'Bags_Unit_Cost'
  | 'NP_Add_YN',
  // Computed
  | 'currency_suggested'
  | 'Operating_Labour_Cost_suggested'
  | 'Electricity_Cost_suggested'
  | 'NaOH_Operating_Cost_suggested'
  | 'Qty_dryer_suggested'
  | 'Natural_Gas_Cost_suggested'
  | 'Hot_Water_Cost_suggested'
  | 'Mg_Operating_Cost_suggested'
  | 'Ammonia_Cost_suggested'
  | 'Acid_Cost_suggested'
  | 'Lab_Hatch_Cost_suggested'
  | 'Pallets_Unit_Cost_suggested'
  | 'Bags_Unit_Cost_suggested'
  | 'Mg_Operating_Unit_suggested',
  // Main computed
  'ww_NaOH_Neat'
> & {
  acidTypes: AcidType[];
};

export const OperatingCostsField: FC<OperatingCostsFieldProps> = memo(
  ({
    set,
    acidTypes,
    currency: userCurrency,
    currency_suggested,
    flowUnitWeb,
    mgType,
    Operating_Labour_Cost,
    Electricity_Cost,
    Operating_Labour_Cost_suggested,
    Electricity_Cost_suggested,
    NaOH_Operating_Cost,
    NaOH_Operating_Unit,
    NaOH_Operating_Cost_suggested,
    ww_NaOH_Neat,
    Dryer_Type,
    Qty_dryer,
    Qty_dryer_suggested,
    Natural_Gas_Cost,
    Natural_Gas_Cost_suggested,
    Hot_Water_Cost,
    Hot_Water_Cost_suggested,
    Mg_Operating_Unit,
    Mg_Operating_Unit_suggested,
    Mg_Operating_Cost,
    Mg_Operating_Cost_suggested,
    ammonia,
    Ammonia_Unit,
    Ammonia_Cost,
    Ammonia_Cost_suggested,
    acid,
    Acid_Unit,
    Acid_Cost,
    Acid_Cost_suggested,
    Lab_Hatch_Cost,
    Lab_Hatch_Cost_suggested,
    Pallets_Unit_Cost,
    Pallets_Unit_Cost_suggested,
    Bags_Unit_Cost,
    Bags_Unit_Cost_suggested,
    Product_Handling_Requested,
    NP_Add_YN,
  }) => {
    const volumeOpts = useVolumeSelectOptions();

    const NaOH_label = `Sodium Hydroxide (${ww_NaOH_Neat * 100}%)`;

    const mgTypeLabel = mgType?.label ?? 'Magnesium Type';

    const ammoniaLabel =
      NP_Add_YN === 'Y' ? ammonia?.symbol_label ?? 'Ammonia Type' : 'Ammonia (Not Used)';

    const acidLabel = acid?.symbol_label ?? 'Acid Type';

    const currency = userCurrency ?? currency_suggested;

    return (
      <Fieldset title="Operating Costs">
        <Grid item xs={12} sm={6}>
          <FormSelectInput
            label="Currency"
            value={userCurrency ?? ''}
            placeholder={currency_suggested?.toString()}
            onChange={(ev) => set('currency', ev.target.value as string)}
          >
            <MenuItem value="$">USD ($)</MenuItem>
            <MenuItem value="C$">CAD (C$)</MenuItem>
            <MenuItem value="£">GBP (£)</MenuItem>
            <MenuItem value="€">EUR (€)</MenuItem>
          </FormSelectInput>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormSelectInput
            label="Product Handling Requested"
            value={Product_Handling_Requested ?? ''}
            onChange={(ev) => set('Product_Handling_Requested', ev.target.value as string)}
          >
            <MenuItem value="Bagging (Silos)">Bagging (Silos)</MenuItem>
            <MenuItem value="Bulk Offloading">Bulk Offloading</MenuItem>
          </FormSelectInput>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormSelectInput
            label="Dryer Heater Type"
            value={Dryer_Type}
            onChange={(ev) => set('Dryer_Type', ev.target.value as string)}
          >
            <MenuItem value="Electric">Electric</MenuItem>
            <MenuItem value="Hot Water">Hot Water</MenuItem>
            <MenuItem value="Steam">Steam</MenuItem>
            <MenuItem value="Natural Gas">Natural Gas</MenuItem>
          </FormSelectInput>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormNumberInput
            label="Quantity of duty dryers"
            value={Qty_dryer ?? ''}
            placeholder={Qty_dryer_suggested?.toString()}
            onChange={(ev) => set('Qty_dryer', numberOf(ev.target.value))}
          />
        </Grid>

        <Grid item xs>
          <TableContainer>
            <Table size="small" style={{ tableLayout: 'fixed', minWidth: 600 }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '33.33%' }}>Input</TableCell>
                  <TableCell>Cost</TableCell>
                  <TableCell>Unit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Operating Labour Cost</TableCell>
                  <TableCell>
                    <FormPriceInput
                      aria-label="Operating Labour Cost (FTE)"
                      value={Operating_Labour_Cost ?? ''}
                      placeholder={Operating_Labour_Cost_suggested?.toString()}
                      onChange={(ev) => set('Operating_Labour_Cost', numberOf(ev.target.value))}
                      currency={currency}
                    />
                  </TableCell>
                  <TableCell>FTE</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{NaOH_label}</TableCell>
                  <TableCell>
                    <FormPriceInput
                      aria-label={`${NaOH_label} ${NaOH_Operating_Unit} cost (${currency})`}
                      value={NaOH_Operating_Cost ?? ''}
                      placeholder={NaOH_Operating_Cost_suggested?.toString()}
                      onChange={(ev) => set('NaOH_Operating_Cost', numberOf(ev.target.value))}
                      currency={currency}
                    />
                  </TableCell>
                  <TableCell>
                    <FormSelectInput
                      aria-label={`${NaOH_label} Unit`}
                      value={NaOH_Operating_Unit}
                      onChange={(ev) => set('NaOH_Operating_Unit', ev.target.value as string)}
                    >
                      {volumeOpts}
                    </FormSelectInput>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Electricity</TableCell>
                  <TableCell>
                    <FormPriceInput
                      aria-label={`Electricity Cost kWh (${currency})`}
                      value={Electricity_Cost ?? ''}
                      placeholder={Electricity_Cost_suggested?.toString()}
                      onChange={(ev) => set('Electricity_Cost', numberOf(ev.target.value))}
                      currency={currency}
                    />
                  </TableCell>
                  <TableCell>kwh</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Natural Gas</TableCell>
                  <TableCell>
                    <FormPriceInput
                      aria-label={`Natural Gas ${
                        flowUnitWeb === 'gal/d' ? 'therm' : 'GJ'
                      } cost (${currency})`}
                      value={Natural_Gas_Cost ?? ''}
                      placeholder={Natural_Gas_Cost_suggested?.toString()}
                      onChange={(ev) => set('Natural_Gas_Cost', numberOf(ev.target.value))}
                      currency={currency}
                    />
                  </TableCell>
                  <TableCell>{flowUnitWeb === 'gal/d' ? 'therm' : 'GJ'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Hot Water</TableCell>
                  <TableCell>
                    <FormPriceInput
                      aria-label={`Hot Water ${
                        flowUnitWeb === 'gal/d' ? 'gal' : 'm3'
                      } cost (${currency})`}
                      value={Hot_Water_Cost ?? ''}
                      placeholder={Hot_Water_Cost_suggested?.toString()}
                      onChange={(ev) => set('Hot_Water_Cost', numberOf(ev.target.value))}
                      currency={currency}
                    />
                  </TableCell>
                  <TableCell>{flowUnitWeb === 'gal/d' ? 'gal' : 'm3'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{mgTypeLabel}</TableCell>
                  <TableCell>
                    <FormPriceInput
                      aria-label={`${mgTypeLabel} ${
                        Mg_Operating_Unit ?? Mg_Operating_Unit_suggested
                      } Cost (${currency})`}
                      value={Mg_Operating_Cost ?? ''}
                      placeholder={Mg_Operating_Cost_suggested?.toString()}
                      onChange={(ev) => set('Mg_Operating_Cost', numberOf(ev.target.value))}
                      currency={currency}
                    />
                  </TableCell>
                  <TableCell>
                    <FormSelectInput
                      aria-label={`${mgTypeLabel} Unit`}
                      value={Mg_Operating_Unit ?? ''}
                      placeholder={Mg_Operating_Unit ? undefined : Mg_Operating_Unit_suggested}
                      onChange={(ev) => set('Mg_Operating_Unit', ev.target.value as string)}
                    >
                      {volumeOpts}
                    </FormSelectInput>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{ammoniaLabel}</TableCell>
                  <TableCell>
                    <FormPriceInput
                      aria-label={`${ammoniaLabel} ${Ammonia_Unit} Cost (${currency})`}
                      value={NP_Add_YN === 'N' ? '' : Ammonia_Cost ?? ''}
                      disabled={NP_Add_YN === 'N'}
                      placeholder={NP_Add_YN === 'N' ? ' ' : Ammonia_Cost_suggested?.toString()}
                      onChange={(ev) => set('Ammonia_Cost', numberOf(ev.target.value))}
                      currency={currency}
                    />
                  </TableCell>
                  <TableCell>
                    <FormSelectInput
                      aria-label={`${ammoniaLabel} Unit`}
                      value={NP_Add_YN === 'N' ? '' : Ammonia_Unit}
                      disabled={NP_Add_YN === 'N'}
                      placeholder={NP_Add_YN === 'N' ? ' ' : undefined}
                      onChange={(ev) => set('Ammonia_Unit', ev.target.value as string)}
                    >
                      {volumeOpts}
                    </FormSelectInput>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <FormSelectInput
                      label="Acid Type"
                      value={acid?.id ?? ''}
                      onChange={(ev) => {
                        set(
                          'acid',
                          acidTypes.find((ac) => ac.id === ev.target.value),
                        );
                      }}
                    >
                      {acidTypes.map((ac) => (
                        <MenuItem key={ac.id} value={ac.id}>
                          {ac.symbol_label}
                        </MenuItem>
                      ))}
                    </FormSelectInput>
                  </TableCell>
                  <TableCell>
                    <FormPriceInput
                      aria-label={`${acidLabel} ${Acid_Unit} Cost (${currency})`}
                      value={Acid_Cost ?? ''}
                      placeholder={Acid_Cost_suggested?.toString()}
                      onChange={(ev) => set('Acid_Cost', numberOf(ev.target.value))}
                      currency={currency}
                    />
                  </TableCell>
                  <TableCell>
                    <FormSelectInput
                      aria-label={`${acidLabel} Unit`}
                      value={Acid_Unit}
                      onChange={(ev) => set('Acid_Unit', ev.target.value as string)}
                    >
                      {volumeOpts}
                    </FormSelectInput>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Hach Kit / Lab Testing</TableCell>
                  <TableCell>
                    <FormPriceInput
                      aria-label={`Lab - Hach Cost (${currency})`}
                      value={Lab_Hatch_Cost ?? ''}
                      placeholder={Lab_Hatch_Cost_suggested?.toString()}
                      onChange={(ev) => set('Lab_Hatch_Cost', numberOf(ev.target.value))}
                      currency={currency}
                    />
                  </TableCell>
                  <TableCell>test</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Pallets</TableCell>
                  <TableCell>
                    <FormPriceInput
                      aria-label={`Pallets ${
                        flowUnitWeb === 'gal/d' ? 'ton' : 'tonne'
                      } cost (${currency})`}
                      value={Pallets_Unit_Cost ?? ''}
                      placeholder={Pallets_Unit_Cost_suggested?.toString()}
                      onChange={(ev) => set('Pallets_Unit_Cost', numberOf(ev.target.value))}
                      currency={currency}
                    />
                  </TableCell>
                  <TableCell>{flowUnitWeb === 'gal/d' ? 'ton' : 'tonne'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Bags</TableCell>
                  <TableCell>
                    <FormPriceInput
                      aria-label={`Bags ${
                        flowUnitWeb === 'gal/d' ? 'ton' : 'tonne'
                      } cost (${currency})`}
                      value={Bags_Unit_Cost ?? ''}
                      placeholder={Bags_Unit_Cost_suggested?.toString()}
                      onChange={(ev) => set('Bags_Unit_Cost', numberOf(ev.target.value))}
                      currency={currency}
                    />
                  </TableCell>
                  <TableCell>{flowUnitWeb === 'gal/d' ? 'ton' : 'tonne'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Fieldset>
    );
  },
);
