import React from 'react';
import { Route, Switch } from 'react-router';
import ProcessModelRuns from './ProcessModelRuns';
import AdminSettings from './AdminSettings';
import { ProcessModelPage } from './ProcessModel/ProcessModelPage';

export default function AdminDashboard() {
  return (
    <Switch>
      <Route exact path="/" component={ProcessModelRuns} />
      <Route exact path="/process-model" component={ProcessModelPage} />
      <Route exact path="/admin/settings" component={AdminSettings} />
    </Switch>
  );
}
