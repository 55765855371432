import React, { FC, memo } from 'react';
import { Fieldset } from '../Fieldset';
import { Grid, MenuItem } from '@material-ui/core';
import { numberOf, ProcessModelFormFieldsetProps } from './util';
import { FormNumberInput, FormSelectInput } from './Inputs';
import { CalculatedNumberLabel } from './CalculatedValues';

type ReactorDataFieldsProps = ProcessModelFormFieldsetProps<
  'MgP_A' | 'Days_Ops_user' | 'NP_Add_YN' | 'NP_D_user',
  'Days_Ops_suggested' | 'NP' | 'NP_D_suggested'
>;

export const ReactorDataFields: FC<ReactorDataFieldsProps> = memo(
  ({ set, MgP_A, Days_Ops_user, Days_Ops_suggested, NP_Add_YN, NP, NP_D_user, NP_D_suggested }) => {
    return (
      <Fieldset title="Reactor Data">
        <Grid item xs={12} sm={6}>
          <FormNumberInput
            label="Activity Mg:P Ratio (mol Mg/mol P)"
            value={MgP_A ?? ''}
            onChange={(ev) => set('MgP_A', numberOf(ev.target.value))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormNumberInput
            label="Days of Operation"
            value={Days_Ops_user ?? ''}
            placeholder={Days_Ops_suggested.toString()}
            onChange={(ev) => set('Days_Ops_user', numberOf(ev.target.value))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormSelectInput
            label="Dosing Ammonia?"
            value={NP_Add_YN}
            onChange={(ev) => set('NP_Add_YN', ev.target.value as 'Y' | 'N')}
          >
            <MenuItem value="N">No</MenuItem>
            <MenuItem value="Y">Yes</MenuItem>
          </FormSelectInput>
        </Grid>
        <Grid item xs={12} sm={6}>
          {NP_Add_YN === 'Y' && (
            <FormNumberInput
              label="Desired N:P Ratio"
              value={NP_D_user ?? ''}
              placeholder={NP_D_suggested?.toString()}
              onChange={(ev) => set('NP_D_user', numberOf(ev.target.value))}
              inputProps={{ min: Number.isFinite(NP) ? NP : undefined }}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <CalculatedNumberLabel label="Actual N:P Ratio (mol N/mol P)" value={NP} />
        </Grid>
      </Fieldset>
    );
  },
);
