import React, { FC, memo } from 'react';
import { Fieldset } from '../Fieldset';
import { CircularProgress, Grid } from '@material-ui/core';
import STButton from '../../base/STButton';
import {
  CalculatedNumberLabel,
  CalculatedPercentageLabel,
  CalculatedValue,
} from './CalculatedValues';

export const ProcessModelPreview: FC<{
  isEnabled: boolean;
  requestPreview: () => void;
  isPreviewLoading: boolean;
  preview?: Record<string, number>;
}> = memo(({ isEnabled, requestPreview, isPreviewLoading, preview }) => {
  return (
    <Fieldset title="Process Model Preview">
      <Grid item xs={12}>
        <STButton disabled={!isEnabled} onClick={() => requestPreview()}>
          Preview
        </STButton>{' '}
        {isPreviewLoading && <CircularProgress size="1.3rem" />}
      </Grid>
      <Grid item xs={6} sm={4}>
        <CalculatedNumberLabel
          value={preview?.Mg_R_mgL ?? null}
          label="Effluent Mg Concentration"
          unit="mg/L"
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <CalculatedNumberLabel
          value={preview?.N_R_mgL ?? null}
          label="Effluent N Concentration"
          unit="mg/L as NH3-N"
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <CalculatedNumberLabel
          value={preview?.P_R_mgL ?? null}
          label="Effluent P Concentration"
          unit="mg/L as PO4-P"
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <CalculatedNumberLabel
          value={preview?.Ca_R_mgL ?? null}
          label="Effluent Ca Concentration"
          unit="mg/L"
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <CalculatedNumberLabel
          value={preview?.Na_R_mgL ?? null}
          label="Effluent Na Concentration"
          unit="mg/L"
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <CalculatedNumberLabel value={preview?.pH_R ?? null} label="pH_R" />
      </Grid>
      <Grid item xs={6} sm={4}>
        <CalculatedPercentageLabel value={preview?.Rem_P ?? null} label="P Removal" />
      </Grid>
      <Grid item xs={6} sm={4}>
        <CalculatedValue>
          <small>Crystal Green Production</small> <br />
          {typeof preview?.Crystal_Green_Low === 'number' &&
          typeof preview?.Crystal_Green_High === 'number'
            ? `${preview.Crystal_Green_Low.toLocaleString('en-US', {
                maximumFractionDigits: 0,
              })} - ${preview.Crystal_Green_High.toLocaleString('en-US', {
                maximumFractionDigits: 0,
              })}`
            : '-'}
        </CalculatedValue>
      </Grid>
    </Fieldset>
  );
});
