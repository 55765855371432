import React, { FC, HTMLAttributes } from 'react';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import { ToastType, useToastContext } from './ToastProvider';

const Toast: FC<HTMLAttributes<any>> = (props) => {
  const { message, clearToast, type } = useToastContext();

  return (
    <div {...props}>
      <Collapse in={!!message}>
        <Alert
          icon={false}
          color={type === ToastType.Error ? 'error' : 'success'}
          action={
            <IconButton aria-label="close" size="small" color="inherit" onClick={clearToast}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Collapse>
    </div>
  );
};

export default Toast;
