import React, { ButtonHTMLAttributes, FC } from 'react';
import '../assets/scss/UnstyledButton.scss';

const UnStyledButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  className,
  ...props
}) => (
  <button className={`${className} unstyled-button`} {...props}>
    {children}
  </button>
);

export default UnStyledButton;
