import S3 from 'aws-sdk/clients/s3';
import { Auth } from 'aws-amplify';
import awsExports from '../aws-exports';

export default async function downloadS3File(s3Key: string, fileName?: string) {
  const s3 = new S3({ apiVersion: '2006-03-01', ...(await Auth.currentUserCredentials()) });

  const data = await s3
    .getObject({
      Key: s3Key,
      Bucket: awsExports.aws_user_files_s3_bucket,
    })
    .promise();

  const blob = new Blob([data.Body as Buffer], {
    type: data.ContentType,
  });

  const anchor = document.createElement('a');
  anchor.href = window.URL.createObjectURL(blob);
  anchor.download = fileName ?? s3Key.split('/').pop() ?? s3Key;

  anchor.click();
}
