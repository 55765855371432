import React, { FormEvent, SyntheticEvent, useRef, useState } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import STInput from '../base/STInput';
import STButton from '../base/STButton';
import Link from '@material-ui/core/Link';
import { Auth } from 'aws-amplify';
import S3 from 'aws-sdk/clients/s3';
import awsExports from '../aws-exports';
import { ToastType, useToastContext } from '../base/ToastProvider';
import downloadS3File from '../lib/download-file';

// same as api/src/process-model-runs/process-model-runs.service.ts:89
const TEMPLATE_NAME = 'process-model-run-template';
const TEMPLATE_KEY = `template/${TEMPLATE_NAME}.xlsx`;

export default function AdminSettings() {
  const upload = useRef<HTMLInputElement | null>(null);
  const { addToast, clearToast } = useToastContext();
  const [uploading, setUploading] = useState(false);

  const downloadTemplate = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      downloadS3File(TEMPLATE_KEY, TEMPLATE_NAME);
      clearToast();
    } catch (err) {
      addToast(`Cannot download file: ${err.message}`, ToastType.Error);
    }
  };

  const uploadImage = async (e: FormEvent) => {
    e.preventDefault();
    setUploading(true);
    const credentials = await Auth.currentUserCredentials();
    const s3 = new S3({ apiVersion: '2006-03-01', ...credentials });
    try {
      await s3!
        .putObject({
          Key: TEMPLATE_KEY,
          Body: upload.current!.files![0],
          Bucket: awsExports.aws_user_files_s3_bucket,
          ContentType: upload.current!.files![0].type,
        })
        .promise();
      upload.current!.value = '';
      addToast('Your file has been uploaded!');
    } catch (err) {
      addToast(`Cannot upload file: ${err.message}`, ToastType.Error);
    }
    setUploading(false);
  };

  return (
    <Container maxWidth="md">
      <Grid container spacing={1}>
        <form onSubmit={uploadImage}>
          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom>
              Settings
            </Typography>

            <Typography variant="body2">
              <b>Process Model Export Template</b>
              <br />
              Upload the Excel(XLSX) file to be used for the Process Model Run exports. If you
              upload a new file, make sure to create a test Process Model Run to verify your XLSX
              file is compatible.{' '}
              <Link href="#" color="secondary" onClick={downloadTemplate}>
                Download the current template
              </Link>
            </Typography>

            <Grid item xs={12}>
              <STInput
                required
                type="file"
                inputRef={upload}
                inputProps={{
                  accept:
                    'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <STButton type="submit" disabled={uploading}>
              Upload Template
            </STButton>
          </Grid>
        </form>
      </Grid>
    </Container>
  );
}
