import React, { FC, useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import normalizedUserInputOf, {
  AcidType,
  AmmoniaType,
  MagnesiumType,
  MetalSaltType,
  processModelComputedInputOf,
  processSecondaryComputedInputOf,
  RawUserInput,
  ReactorModel,
} from './userInput';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import STButton from '../../base/STButton';
import { useMutation } from 'react-query';
import { salesToolApiFetch } from '../../lib/sales-tool-api';
import { ToastType, useToastContext } from '../../base/ToastProvider';
import { clearProcessModelFormInput } from './util';
import { useHistory } from 'react-router';
import downloadS3File from '../../lib/download-file';
import { Auth } from 'aws-amplify';
import { RequiredProcessModelConstants } from './processModelConstants';
import MainFormFields from './MainFormFields';
import { ProcessModelPreview } from './ProcessModelPreview';
import { SecondaryFormFields } from './SecondaryFormFields';
import { fmtPercentage } from './CalculatedValues';

type Actions = { type: 'SET_INPUT'; change: Partial<RawUserInput> };

function reducer(state: RawUserInput, action: Actions): RawUserInput {
  switch (action.type) {
    case 'SET_INPUT':
      return { ...state, ...action.change };
    default:
      throw new Error();
  }
}

interface ProcessModelFormProps {
  initialState: RawUserInput;
  onChange?: (input: RawUserInput) => void;
  constants: RequiredProcessModelConstants;
  reactors: ReactorModel[];
  mgTypes: MagnesiumType[];
  metalSaltTypes: MetalSaltType[];
  ammoniaTypes: AmmoniaType[];
  acidTypes: AcidType[];
}

const ProcessModelForm: FC<ProcessModelFormProps> = ({
  initialState,
  onChange,
  constants,
  reactors,
  mgTypes,
  metalSaltTypes,
  ammoniaTypes: rawAmmoniaTypes,
  acidTypes,
}) => {
  const [rawUserInput, dispatch] = useReducer(reducer, initialState, (init) => {
    if (!init.acid) {
      const defaultAcid = acidTypes.find((r) => r.default);
      if (defaultAcid) return { ...init, acid: defaultAcid };
    }

    return init;
  });

  const updateRawInput = useCallback(
    <T extends keyof RawUserInput>(key: T, value: RawUserInput[T]) =>
      dispatch({ type: 'SET_INPUT', change: { [key]: value } }),
    [],
  );

  /// Reactors
  const rawReactorModel = rawUserInput.reactorModel;
  const liveReactorModel = useMemo(() => {
    return (
      rawReactorModel && (reactors.find((r) => r.name === rawReactorModel.name) ?? rawReactorModel)
    );
  }, [rawReactorModel, reactors]);

  useEffect(() => {
    if (rawReactorModel !== liveReactorModel) updateRawInput('reactorModel', liveReactorModel);
  }, [liveReactorModel, rawReactorModel, updateRawInput]);
  ///

  /// Mg Feed types
  const { ww_MgCl2_Neat = 0 } = rawUserInput;
  const expandedMgTypes = useMemo(
    (): MagnesiumType[] => [
      {
        id: 'MgCl2',
        label: `Magnesium Chloride (${fmtPercentage(ww_MgCl2_Neat)})`,
        causticOffset: 0,
        mg_P_Activity_Multiplier: 1,
      },
      ...mgTypes.filter(({ id }) => id !== 'MgCl2'),
    ],
    [mgTypes, ww_MgCl2_Neat],
  );

  const rawMgType = rawUserInput.mgType;
  const liveMgType = useMemo(() => {
    return (rawMgType && expandedMgTypes.find((m) => m.id === rawMgType.id)) ?? rawMgType;
  }, [expandedMgTypes, rawMgType]);

  useEffect(() => {
    if (rawMgType !== liveMgType) updateRawInput('mgType', liveMgType);
  }, [liveMgType, rawMgType, updateRawInput]);
  ///

  /// Ammonia
  const ammoniaTypes = rawAmmoniaTypes;
  const rawAmmonia = rawUserInput.ammonia;
  const liveAmmonia = useMemo(
    () => rawAmmonia && (ammoniaTypes.find((r) => r.id === rawAmmonia.id) ?? rawAmmonia),
    [ammoniaTypes, rawAmmonia],
  );

  useEffect(() => {
    if (rawAmmonia !== liveAmmonia) updateRawInput('ammonia', liveAmmonia);
  }, [liveAmmonia, rawAmmonia, updateRawInput]);
  ///

  /// Acids
  const rawAcid = rawUserInput.acid;
  const liveAcid = useMemo(
    () => rawAcid && (acidTypes.find((r) => r.id === rawAcid.id) ?? rawAcid),
    [acidTypes, rawAcid],
  );

  useEffect(() => {
    if (rawAcid !== liveAcid) updateRawInput('acid', liveAcid);
  }, [liveAcid, rawAcid, updateRawInput]);
  ///

  const normalizedUserInput = useMemo(() => normalizedUserInputOf(rawUserInput), [rawUserInput]);

  const secondaryComputedInput = useMemo(
    () => processSecondaryComputedInputOf(constants, normalizedUserInput),
    [constants, normalizedUserInput],
  );

  /// Raw Metals
  const { MetalSalt_Solution_Concentration } = secondaryComputedInput;
  const expandedMetalSalts = useMemo(
    () =>
      metalSaltTypes.map(
        (m): MetalSaltType => ({
          ...m,
          symbol_label: `${m.symbol_label} (${fmtPercentage(MetalSalt_Solution_Concentration)})`,
        }),
      ),
    [metalSaltTypes, MetalSalt_Solution_Concentration],
  );

  const rawMetalSalt = rawUserInput.metalSalt;
  const liveMetalSalt = useMemo(() => {
    return (
      (rawMetalSalt && expandedMetalSalts.find((m) => m.id === rawMetalSalt.id)) ?? rawMetalSalt
    );
  }, [expandedMetalSalts, rawMetalSalt]);

  useEffect(() => {
    if (rawMetalSalt !== liveMetalSalt) updateRawInput('metalSalt', liveMetalSalt);
  }, [liveMetalSalt, rawMetalSalt, updateRawInput]);
  ///

  const mainComputedInput = useMemo(
    () => processModelComputedInputOf(constants, reactors, normalizedUserInput),
    [constants, normalizedUserInput, reactors],
  );

  useEffect(() => onChange?.(rawUserInput), [onChange, rawUserInput]);

  const mainFormRef = useRef<HTMLFormElement>(null);
  const secondaryFormRef = useRef<HTMLFormElement>(null);
  const { addToast, clearToast } = useToastContext();

  const {
    mutate: requestPreview,
    data: dataSrc,
    isLoading: isPreviewLoading,
  } = useMutation(async () => {
    try {
      clearToast();
      const credentials = await Auth.currentUserCredentials();

      return await salesToolApiFetch<{ output: Record<string, number> }>(
        '/processModelRuns/preview',
        {
          method: 'POST',
          body: JSON.stringify({
            userInput: rawUserInput,
            calculatedInput: mainComputedInput,
            cognitoId: credentials.identityId,
          }),
        },
      );
    } catch (err) {
      addToast(`Preview error: ${err.message}`, ToastType.Error);
      return null;
    }
  });

  const history = useHistory();

  const { mutate: exportResults, isLoading: isExportLoading } = useMutation(async () => {
    let s3Key;
    try {
      clearToast();
      const credentials = await Auth.currentUserCredentials();

      s3Key = (
        await salesToolApiFetch<{ s3Key: string }>('/processModelRuns', {
          method: 'POST',
          body: JSON.stringify({
            userInput: rawUserInput,
            calculatedInput: { ...mainComputedInput, ...secondaryComputedInput },
            cognitoId: credentials.identityId,
          }),
        })
      ).s3Key;
    } catch (err) {
      addToast(`Preview error: ${err.message}`, ToastType.Error);
      return null;
    }

    try {
      downloadS3File(s3Key);

      history.push('/');

      clearProcessModelFormInput();
    } catch (err) {
      addToast(`Cannot download file: ${err.message}`, ToastType.Error);
    }
  });

  const isLoading = isPreviewLoading || isExportLoading;

  const preview = dataSrc?.output;

  const previewCb = useCallback(() => {
    if (mainFormRef.current?.reportValidity()) requestPreview();
  }, [requestPreview]);

  const exportCb = useCallback(() => {
    if (mainFormRef.current?.reportValidity() && secondaryFormRef.current?.reportValidity())
      exportResults();
  }, [exportResults]);

  return (
    <>
      <Typography variant="h2" gutterBottom>
        New Process Model Run
      </Typography>

      <form
        ref={mainFormRef}
        onSubmit={(ev) => {
          ev.preventDefault();
        }}
      >
        <MainFormFields
          computedInput={mainComputedInput}
          rawInput={rawUserInput}
          updateRawInput={updateRawInput}
          reactors={reactors}
          mgTypes={expandedMgTypes}
          ammoniaTypes={ammoniaTypes}
        />
        <ProcessModelPreview
          isEnabled={!isLoading}
          isPreviewLoading={isPreviewLoading}
          requestPreview={previewCb}
          preview={preview}
        />
      </form>

      <form
        ref={secondaryFormRef}
        onSubmit={(ev) => {
          ev.preventDefault();
        }}
      >
        <SecondaryFormFields
          mainComputedInput={mainComputedInput}
          computedInput={secondaryComputedInput}
          rawInput={rawUserInput}
          updateRawInput={updateRawInput}
          metalSaltTypes={expandedMetalSalts}
          ammoniaTypes={ammoniaTypes}
          acidTypes={acidTypes}
        />
      </form>

      <Grid item xs={12} style={{ marginBottom: '10vh', marginTop: '5vh' }}>
        <STButton disabled={isLoading} onClick={exportCb}>
          Export Results & Finalize
        </STButton>
        {isExportLoading && <CircularProgress size="1.3rem" />}
      </Grid>
    </>
  );
};

export default ProcessModelForm;
