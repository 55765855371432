import React, { useMemo } from 'react';
import { MenuItem } from '@material-ui/core';

export function useVolumeSelectOptions() {
  return useMemo(
    () => [
      <MenuItem key={'gal'} value={'gal'}>
        gal
      </MenuItem>,
      <MenuItem key={'dry ton'} value={'dry ton'}>
        dry ton
      </MenuItem>,
      <MenuItem key={'wet ton'} value={'wet ton'}>
        wet ton
      </MenuItem>,
      <MenuItem key={'dry lb'} value={'dry lb'}>
        dry lb
      </MenuItem>,
      <MenuItem key={'wet lb'} value={'wet lb'}>
        wet lb
      </MenuItem>,
      <MenuItem key={'L'} value={'L'}>
        L
      </MenuItem>,
      <MenuItem key={'m3'} value={'m3'}>
        m3
      </MenuItem>,
      <MenuItem key={'dry tonne'} value={'dry tonne'}>
        dry tonne
      </MenuItem>,
      <MenuItem key={'wet tonne'} value={'wet tonne'}>
        wet tonne
      </MenuItem>,
      <MenuItem key={'dry kg'} value={'dry kg'}>
        dry kg
      </MenuItem>,
      <MenuItem key={'wet kg'} value={'wet kg'}>
        wet kg
      </MenuItem>,
    ],
    [],
  );
}
