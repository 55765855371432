import { jsonFetch } from './json-fetch';
import { useQuery } from 'react-query';
import { QueryFunction, QueryKey } from 'react-query/types/core/types';
import { UseQueryOptions } from 'react-query/types/react/types';
import { Auth } from 'aws-amplify';

const API_URL = process.env.REACT_APP_SALES_TOOL_API_ENDPOINT;

export async function salesToolApiFetch<T>(path: string, init?: RequestInit) {
  return jsonFetch<T>(API_URL + path, {
    ...init,
    headers: {
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      ...init?.headers,
    },
  });
}

/**
 * React-query query helper
 *
 * @see https://react-query.tanstack.com/guides/queries
 * @example
 *
 *   const { isLoading, isError, data, error } = useSalesToolQuery<ProcessModelRun[]>('processModelRuns', '/processModelRuns');
 *
 *   useSalesToolQuery('processModelRuns', () => {
 *     return salesToolApiFetch<ProcessModelRun[]>('/processModelRuns?' + new URLSearchParams({
            foo: 'value',
 *          bar: 2,
 *      }, {
 *        headers: {
 *          MyCustomHeader: 'foo'
 *        }
 *      })
 *   })
 */
export function useSalesToolQuery<T = unknown>(
  queryKey: QueryKey,
  pathOrFn: string | QueryFunction<T>,
  options?: UseQueryOptions<T, Error>,
) {
  return useQuery<T, Error>(
    queryKey,
    typeof pathOrFn == 'string' ? () => salesToolApiFetch<T>(pathOrFn) : pathOrFn,
    options,
  );
}
