import React, { FC } from 'react';
import { useAuthContext } from '../components/AuthContext';
import {
  AppBar,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/logo.png';
import { AccountCircle, Menu as MenuIcon, List as ListIcon, Settings } from '@material-ui/icons';
import SignOut from '../components/SignOut';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import ToastContextProvider from './ToastProvider';
import Toast from './Toast';
import { IDENTITY_PROVIDER } from '../index';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuItem: {
    color: 'black',
    textDecoration: 'none',
  },
  sectionDesktop: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
  },
  toolbar: theme.mixins.toolbar,
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerContainer: {
    overflow: 'auto',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    maxWidth: '100%',
  },
  container: {
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(8),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5),
    },
  },
  toggleButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  headerBar: {
    marginLeft: 30,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  listItem: {
    paddingTop: 0,
  },
  logOutBtn: {
    width: '100%',
    textAlign: 'left',
    minWidth: '100px',
  },
  toastContainer: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
}));

const Layout: FC<{ isAdmin: boolean }> = ({ isAdmin, children }) => {
  const { user, getProviderName } = useAuthContext();
  const classes = useStyles();
  const menuId = 'sales-main-menu';
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { pathname } = useLocation();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const renderMenu = (
    <Menu
      id={menuId}
      keepMounted
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleMenuClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      {getProviderName!(user) !== IDENTITY_PROVIDER && (
        <MenuItem onClick={handleMenuClose}>
          <Link className={classes.menuItem} to="/update-password">
            Update password
          </Link>
        </MenuItem>
      )}

      <MenuItem onClick={handleMenuClose}>
        <SignOut className={classes.logOutBtn}>Sign out</SignOut>
      </MenuItem>
    </Menu>
  );

  const drawer = (
    <>
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List className={classes.listItem}>
          <Divider />
          <ListItem
            to="/"
            button
            selected={pathname === '/'}
            component={Link}
            onClick={() => mobileOpen && handleDrawerToggle()}
          >
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Process Model Runs" />
          </ListItem>
          <Divider />
          {isAdmin && (
            <>
              <ListItem
                to="/admin/settings"
                button
                selected={pathname === '/admin/settings'}
                component={Link}
                onClick={() => mobileOpen && handleDrawerToggle()}
              >
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItem>
              <Divider />
            </>
          )}
        </List>
      </div>
    </>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.toggleButton}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/">
            <img src={logo} alt="logo" style={{ maxWidth: 100 }} />
          </Link>

          <Typography variant="h2" className={classes.headerBar}>
            Process Model Tool
          </Typography>

          <div className={classes.sectionDesktop}>
            <Typography variant="caption" display="block">
              Hello, {user.attributes.name} {user.attributes.middle_name}
            </Typography>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer}>
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content}>
        <Toolbar />
        <ToastContextProvider>
          <Toast className={classes.toastContainer} />
          <div className={classes.container}>{children}</div>
        </ToastContextProvider>
      </main>

      {renderMenu}
    </div>
  );
};

export default Layout;
