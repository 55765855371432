import { API, Auth } from 'aws-amplify';
import { useQuery } from 'react-query';
import { QueryFunction, QueryKey } from 'react-query/types/core/types';
import { UseQueryOptions } from 'react-query/types/react/types';
import { ListUsersResponse } from 'aws-sdk/clients/cognitoidentityserviceprovider';

export async function salesToolAmplifyApiFetch<T>(path: string, init?: any): Promise<T> {
  return API.get('AdminQueries', path, {
    ...init,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  });
}

function useSalesToolAmplifyQuery<T = unknown>(
  queryKey: QueryKey,
  pathOrFn: string | QueryFunction<T>,
  options?: UseQueryOptions<T, Error>,
) {
  return useQuery<T, Error>(
    queryKey,
    typeof pathOrFn == 'string' ? () => salesToolAmplifyApiFetch<T>(pathOrFn) : pathOrFn,
    options,
  );
}

export function useListUsers() {
  return useSalesToolAmplifyQuery<ListUsersResponse>('listUsers', '/listUsers');
}
