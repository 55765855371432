import React, { FC } from 'react';
import {
  AcidType,
  AmmoniaType,
  MetalSaltType,
  ProcessModelComputedInput,
  RawUserInput,
  SecondaryModelComputedInput,
} from './userInput';
import { OperatingCostsField } from './OperatingCostsField';
import { ValuePropFields } from './ValuePropFields';

interface SecondaryFormFieldProps {
  rawInput: RawUserInput;
  updateRawInput: <T extends keyof RawUserInput>(key: T, value: RawUserInput[T]) => void;
  computedInput: SecondaryModelComputedInput;
  mainComputedInput: ProcessModelComputedInput;
  metalSaltTypes: MetalSaltType[];
  ammoniaTypes: AmmoniaType[];
  acidTypes: AcidType[];
}

export const SecondaryFormFields: FC<SecondaryFormFieldProps> = ({
  rawInput,
  updateRawInput: set,
  computedInput: computed,
  mainComputedInput: mainComputed,
  metalSaltTypes,
  acidTypes,
}) => {
  return (
    <>
      <OperatingCostsField
        set={set}
        acidTypes={acidTypes}
        currency={rawInput.currency}
        currency_suggested={computed.currency_suggested}
        Operating_Labour_Cost={rawInput.Operating_Labour_Cost}
        Electricity_Cost={rawInput.Electricity_Cost}
        Operating_Labour_Cost_suggested={computed.Operating_Labour_Cost_suggested}
        Electricity_Cost_suggested={computed.Electricity_Cost_suggested}
        NaOH_Operating_Cost={rawInput.NaOH_Operating_Cost}
        NaOH_Operating_Unit={rawInput.NaOH_Operating_Unit}
        NaOH_Operating_Cost_suggested={computed.NaOH_Operating_Cost_suggested}
        Dryer_Type={rawInput.Dryer_Type}
        Qty_dryer={rawInput.Qty_dryer}
        Qty_dryer_suggested={computed.Qty_dryer_suggested}
        flowUnitWeb={rawInput.flowUnitWeb}
        mgType={rawInput.mgType}
        Natural_Gas_Cost={rawInput.Natural_Gas_Cost}
        Natural_Gas_Cost_suggested={computed.Natural_Gas_Cost_suggested}
        Hot_Water_Cost={rawInput.Hot_Water_Cost}
        Hot_Water_Cost_suggested={computed.Hot_Water_Cost_suggested}
        Mg_Operating_Unit={rawInput.Mg_Operating_Unit}
        Mg_Operating_Unit_suggested={computed.Mg_Operating_Unit_suggested}
        Mg_Operating_Cost={rawInput.Mg_Operating_Cost}
        Mg_Operating_Cost_suggested={computed.Mg_Operating_Cost_suggested}
        ammonia={rawInput.ammonia}
        Ammonia_Unit={rawInput.Ammonia_Unit}
        Ammonia_Cost={rawInput.Ammonia_Cost}
        Ammonia_Cost_suggested={computed.Ammonia_Cost_suggested}
        acid={rawInput.acid}
        Acid_Unit={rawInput.Acid_Unit}
        Acid_Cost={rawInput.Acid_Cost}
        Acid_Cost_suggested={computed.Acid_Cost_suggested}
        Lab_Hatch_Cost={rawInput.Lab_Hatch_Cost}
        Lab_Hatch_Cost_suggested={computed.Lab_Hatch_Cost_suggested}
        Pallets_Unit_Cost={rawInput.Pallets_Unit_Cost}
        Pallets_Unit_Cost_suggested={computed.Pallets_Unit_Cost_suggested}
        Bags_Unit_Cost={rawInput.Bags_Unit_Cost}
        Bags_Unit_Cost_suggested={computed.Bags_Unit_Cost_suggested}
        Product_Handling_Requested={rawInput.Product_Handling_Requested}
        ww_NaOH_Neat={mainComputed.ww_NaOH_Neat}
        NP_Add_YN={rawInput.NP_Add_YN}
      />
      <ValuePropFields
        set={set}
        currency={computed.currency}
        metalSaltTypes={metalSaltTypes}
        metalSalt={rawInput.metalSalt}
        MetalSalt_Unit={rawInput.MetalSalt_Unit}
        MetalSalt_Cost={rawInput.MetalSalt_Cost}
        MetalSalt_Cost_suggested={computed.MetalSalt_Cost_suggested}
        MetalSalt_Solution_Concentration={rawInput.MetalSalt_Solution_Concentration}
        MetalSalt_Solution_Concentration_suggested={
          computed.MetalSalt_Solution_Concentration_suggested
        }
        Metal_P_Ratio={rawInput.Metal_P_Ratio}
        Metal_P_Ratio_suggested={computed.Metal_P_Ratio_suggested}
        Alk_Consumption_Required={rawInput.Alk_Consumption_Required}
        flowUnitWeb={rawInput.flowUnitWeb}
        Polymer_Use={rawInput.Polymer_Use}
        Polymer_Cost={rawInput.Polymer_Cost}
        Sludge_Disposal_Cost={rawInput.Sludge_Disposal_Cost}
        Cake_Solids={rawInput.Cake_Solids}
        Plant_Sludge_Production={rawInput.Plant_Sludge_Production}
        WASSTRIP_Cake_Solids_Improvement={rawInput.WASSTRIP_Cake_Solids_Improvement}
        WASSTRIP_Polymer_Reduction={rawInput.WASSTRIP_Polymer_Reduction}
        Ammonia_Removal_Cost={rawInput.Ammonia_Removal_Cost}
        Crystal_Green_Offtake_Price={rawInput.Crystal_Green_Offtake_Price}
        Polymer_Use_suggested={computed.Polymer_Use_suggested}
        Polymer_Cost_suggested={computed.Polymer_Cost_suggested}
        Sludge_Disposal_Cost_suggested={computed.Sludge_Disposal_Cost_suggested}
        Cake_Solids_suggested={computed.Cake_Solids_suggested}
        Plant_Sludge_Production_suggested={computed.Plant_Sludge_Production_suggested}
        WASSTRIP_Cake_Solids_Improvement_suggested={
          computed.WASSTRIP_Cake_Solids_Improvement_suggested
        }
        WASSTRIP_Polymer_Reduction_suggested={computed.WASSTRIP_Polymer_Reduction_suggested}
        Ammonia_Removal_Cost_suggested={computed.Ammonia_Removal_Cost_suggested}
        Crystal_Green_Offtake_Price_suggested={computed.Crystal_Green_Offtake_Price_suggested}
      />
    </>
  );
};
