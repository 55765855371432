import React, { FC, useMemo } from 'react';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import useUsers from './useUsers';
import { User } from './User';

const STUsersSelector: FC<any> = ({ label, onChange, value }) => {
  const { users, error, getUserById } = useUsers();
  const user = useMemo(() => getUserById(value), [getUserById, value]);

  return error ? (
    <Typography variant="caption" gutterBottom color="error">
      Server Error: There was an error trying to get users
    </Typography>
  ) : (
    <Autocomplete
      onChange={onChange}
      options={users}
      getOptionLabel={(option: User) => option.name}
      value={user}
      renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
    />
  );
};

export default STUsersSelector;
