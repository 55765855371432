import { AuthState, CognitoUserInterface } from '@aws-amplify/ui-components';

interface State {
  isAdmin: boolean;
  loading?: boolean;
  user?: CognitoUserInterface;
  showFederatedLogin?: boolean;
}

export const initAuthReducer: State = {
  isAdmin: false,
  loading: false,
  user: undefined,
  showFederatedLogin: false,
};

export enum AUTH_TYPES_REDUCER {
  UPDATE_AUTH_STATE = 'UPDATE_AUTH_STATE',
  UPDATE_LOADING = 'UPDATE_LOADING',
  SET_IS_ADMIN = 'SET_IS_ADMIN',
}

interface Action {
  type: AUTH_TYPES_REDUCER;
  nextAuthState?: AuthState;
  authData?: CognitoUserInterface;
  loading?: boolean;
  isAdmin: boolean;
  showFederatedLogin?: boolean;
}

function assertUnreachable(_: never): never {
  throw new Error("Didn't expect to get here");
}

export default function authReducer(state: State, action: Action) {
  switch (action.type) {
    case AUTH_TYPES_REDUCER.UPDATE_AUTH_STATE:
      if (action.nextAuthState === AuthState.SignIn)
        return { ...initAuthReducer, showFederatedLogin: true };
      if (action.nextAuthState === AuthState.SignedIn) return { ...state, user: action.authData };
      else return initAuthReducer;
    case AUTH_TYPES_REDUCER.UPDATE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case AUTH_TYPES_REDUCER.SET_IS_ADMIN:
      return {
        ...state,
        loading: false,
        isAdmin: action.isAdmin,
      };
    default:
      assertUnreachable(action.type);
  }
}
