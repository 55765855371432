import React, { FC, memo } from 'react';
import { numberOf, SecondaryModelFormFieldsetProps } from './util';
import { MetalSaltType } from './userInput';
import { useVolumeSelectOptions } from './useVolumeSelectOptions';
import { Fieldset } from '../Fieldset';
import { Grid, MenuItem } from '@material-ui/core';
import { FormNumberInput, FormPercentageInput, FormPriceInput, FormSelectInput } from './Inputs';

type ValuePropFieldsProps = SecondaryModelFormFieldsetProps<
  | 'metalSalt'
  | 'MetalSalt_Unit'
  | 'MetalSalt_Cost'
  | 'MetalSalt_Solution_Concentration'
  | 'Metal_P_Ratio'
  | 'Alk_Consumption_Required'
  | 'flowUnitWeb'
  | 'Polymer_Use'
  | 'Polymer_Cost'
  | 'Sludge_Disposal_Cost'
  | 'Cake_Solids'
  | 'Plant_Sludge_Production'
  | 'WASSTRIP_Cake_Solids_Improvement'
  | 'WASSTRIP_Polymer_Reduction'
  | 'Ammonia_Removal_Cost'
  | 'Crystal_Green_Offtake_Price',
  // Computed
  | 'MetalSalt_Cost_suggested'
  | 'MetalSalt_Solution_Concentration_suggested'
  | 'currency'
  | 'Metal_P_Ratio_suggested'
  | 'Polymer_Use_suggested'
  | 'Polymer_Cost_suggested'
  | 'Sludge_Disposal_Cost_suggested'
  | 'Cake_Solids_suggested'
  | 'Plant_Sludge_Production_suggested'
  | 'WASSTRIP_Cake_Solids_Improvement_suggested'
  | 'WASSTRIP_Polymer_Reduction_suggested'
  | 'Ammonia_Removal_Cost_suggested'
  | 'Crystal_Green_Offtake_Price_suggested'
> & {
  metalSaltTypes: MetalSaltType[];
};

export const ValuePropFields: FC<ValuePropFieldsProps> = memo(
  ({
    set,
    metalSaltTypes,
    metalSalt,
    MetalSalt_Unit,
    MetalSalt_Cost,
    MetalSalt_Cost_suggested,
    MetalSalt_Solution_Concentration,
    MetalSalt_Solution_Concentration_suggested,
    currency,
    Metal_P_Ratio,
    Metal_P_Ratio_suggested,
    Alk_Consumption_Required,
    flowUnitWeb,
    Polymer_Use,
    Polymer_Cost,
    Sludge_Disposal_Cost,
    Cake_Solids,
    Plant_Sludge_Production,
    WASSTRIP_Cake_Solids_Improvement,
    WASSTRIP_Polymer_Reduction,
    Ammonia_Removal_Cost,
    Crystal_Green_Offtake_Price,
    Polymer_Use_suggested,
    Polymer_Cost_suggested,
    Sludge_Disposal_Cost_suggested,
    Cake_Solids_suggested,
    Plant_Sludge_Production_suggested,
    WASSTRIP_Cake_Solids_Improvement_suggested,
    WASSTRIP_Polymer_Reduction_suggested,
    Ammonia_Removal_Cost_suggested,
    Crystal_Green_Offtake_Price_suggested,
  }) => {
    const volumeOpts = useVolumeSelectOptions();

    const metalSaltLabel = metalSalt?.symbol_label ?? 'Metal Salt';
    return (
      <React.Fragment>
        <Fieldset title="Metal Salt Addition">
          <Grid item xs={12} sm={6} md={4}>
            <FormSelectInput
              label="Metal Salt"
              value={metalSalt?.id ?? ''}
              onChange={(ev) => {
                set(
                  'metalSalt',
                  metalSaltTypes.find((m) => m.id === ev.target.value),
                );
              }}
            >
              {metalSaltTypes.map((metal) => (
                <MenuItem key={metal.id} value={metal.id}>
                  {metal.symbol_label}
                </MenuItem>
              ))}
            </FormSelectInput>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormSelectInput
              label={`${metalSaltLabel} Unit`}
              value={MetalSalt_Unit}
              onChange={(ev) => set('MetalSalt_Unit', ev.target.value as string)}
            >
              {volumeOpts}
            </FormSelectInput>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormPriceInput
              label={`${metalSaltLabel}  Cost`}
              value={MetalSalt_Cost ?? ''}
              placeholder={MetalSalt_Cost_suggested?.toString()}
              onChange={(ev) => set('MetalSalt_Cost', numberOf(ev.target.value))}
              currency={currency}
              unit={MetalSalt_Unit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormPercentageInput
              label={`${metalSaltLabel} solution concentration`}
              placeholder={MetalSalt_Solution_Concentration_suggested}
              value={MetalSalt_Solution_Concentration ?? ''}
              onChange={(ev) => set('MetalSalt_Solution_Concentration', numberOf(ev.target.value))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormNumberInput
              label={`${metalSalt?.metal ?? 'Metal'}:P Ratio (mol:mol)`}
              value={Metal_P_Ratio ?? ''}
              placeholder={Metal_P_Ratio_suggested?.toString()}
              onChange={(ev) => set('Metal_P_Ratio', numberOf(ev.target.value))}
            />
          </Grid>
        </Fieldset>

        <Fieldset title="Alkalinity Demand">
          <Grid item xs={12} sm={6}>
            <FormSelectInput
              label="Alkalinity Consumption"
              value={Alk_Consumption_Required}
              onChange={(ev) => set('Alk_Consumption_Required', ev.target.value as string)}
            >
              <MenuItem value={'required'}>Required</MenuItem>
              <MenuItem value={'not_required'}>Not Required</MenuItem>
            </FormSelectInput>
          </Grid>
        </Fieldset>

        <Fieldset title="Dewatering">
          <Grid item xs={12} sm={6}>
            <FormNumberInput
              label={`Polymer Use (${flowUnitWeb === 'gal/d' ? 'lbs/dry ton' : 'kg/dry tonne'})`}
              value={Polymer_Use ?? ''}
              placeholder={Polymer_Use_suggested?.toString()}
              onChange={(ev) => set('Polymer_Use', numberOf(ev.target.value))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormPriceInput
              label={`Polymer Cost`}
              value={Polymer_Cost ?? ''}
              placeholder={Polymer_Cost_suggested?.toString()}
              onChange={(ev) => set('Polymer_Cost', numberOf(ev.target.value))}
              currency={currency}
              unit={flowUnitWeb === 'gal/d' ? 'lb' : 'kg'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormPercentageInput
              label="WASSTRIP polymer reduction"
              value={WASSTRIP_Polymer_Reduction ?? ''}
              placeholder={WASSTRIP_Polymer_Reduction_suggested}
              onChange={(ev) => set('WASSTRIP_Polymer_Reduction', numberOf(ev.target.value))}
            />
          </Grid>
        </Fieldset>

        <Fieldset title="Sludge Disposal">
          <Grid item xs={12} sm={6}>
            <FormPriceInput
              label={`Sludge Disposal Cost`}
              value={Sludge_Disposal_Cost ?? ''}
              placeholder={Sludge_Disposal_Cost_suggested?.toString()}
              onChange={(ev) => set('Sludge_Disposal_Cost', numberOf(ev.target.value))}
              currency={currency}
              unit={flowUnitWeb === 'gal/d' ? 'wet ton' : 'wet tonne'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormPercentageInput
              label="Cake Solids"
              value={Cake_Solids ?? ''}
              placeholder={Cake_Solids_suggested}
              onChange={(ev) => set('Cake_Solids', numberOf(ev.target.value))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormNumberInput
              label={`Plant Sludge Production (${
                flowUnitWeb === 'gal/d' ? 'dry ton/d' : 'dry tonne/d'
              })`}
              value={Plant_Sludge_Production ?? ''}
              placeholder={
                typeof Plant_Sludge_Production_suggested === 'number'
                  ? Plant_Sludge_Production_suggested.toLocaleString('en-US', {
                      maximumFractionDigits: 4,
                    })
                  : undefined
              }
              onChange={(ev) => set('Plant_Sludge_Production', numberOf(ev.target.value))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormPercentageInput
              label="WASSTRIP cake solids improvement"
              value={WASSTRIP_Cake_Solids_Improvement ?? ''}
              placeholder={WASSTRIP_Cake_Solids_Improvement_suggested}
              onChange={(ev) => set('WASSTRIP_Cake_Solids_Improvement', numberOf(ev.target.value))}
            />
          </Grid>
        </Fieldset>

        <Fieldset title="Ammonia Removal">
          <Grid item xs={12} sm={6}>
            <FormPriceInput
              label={`Ammonia removal cost`}
              value={Ammonia_Removal_Cost ?? ''}
              placeholder={Ammonia_Removal_Cost_suggested?.toString()}
              onChange={(ev) => set('Ammonia_Removal_Cost', numberOf(ev.target.value))}
              currency={currency}
              unit={flowUnitWeb === 'gal/d' ? 'lb' : 'kg'}
            />
          </Grid>
        </Fieldset>

        <Fieldset title="Fertilizer Revenue">
          <Grid item xs={12} sm={6}>
            <FormPriceInput
              label={`Crystal Green Offtake Price`}
              value={Crystal_Green_Offtake_Price ?? ''}
              placeholder={Crystal_Green_Offtake_Price_suggested?.toString()}
              onChange={(ev) => set('Crystal_Green_Offtake_Price', numberOf(ev.target.value))}
              currency={currency}
              unit={flowUnitWeb === 'gal/d' ? 'ton' : 'tonne'}
            />
          </Grid>
        </Fieldset>
      </React.Fragment>
    );
  },
);
