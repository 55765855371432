import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import useUsers from '../base/useUsers';
import { useAuthContext } from './AuthContext';

const ProcessModelRunsCaption: FC<{ total: number | undefined; selectedUser?: string }> = ({
  total,
  selectedUser,
}) => {
  const { isAdmin } = useAuthContext();
  const { users, getUserById } = useUsers();

  return (
    <Grid container justify="flex-end">
      <div style={{ visibility: total && users ? 'visible' : 'hidden', height: 20 }}>
        {total && users && (
          <>
            {total} Process Model Run{total > 1 && 's'}
            {isAdmin && (
              <>
                &nbsp;by&nbsp;
                {selectedUser
                  ? getUserById(selectedUser)?.name
                  : `${users.length} user${users.length > 1 && 's'}`}
              </>
            )}
          </>
        )}
      </div>
    </Grid>
  );
};

export default ProcessModelRunsCaption;
