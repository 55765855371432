export enum ProcessModelRunStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  OBSOLETE = 'OBSOLETE',
}

export function labelOfProcessModelRunStatus(status: ProcessModelRunStatus): string {
  switch (status) {
    case ProcessModelRunStatus.ACTIVE:
      return 'Active';
    case ProcessModelRunStatus.INACTIVE:
      return 'Inactive';
    case ProcessModelRunStatus.DELETED:
      return 'Deleted';
    case ProcessModelRunStatus.OBSOLETE:
      return 'Obsolete';
  }
}

export function processModelRunStatuses() {
  return Object.values(ProcessModelRunStatus);
}
