import React, { FC, memo } from 'react';
import { Fieldset } from '../Fieldset';
import { Grid, MenuItem } from '@material-ui/core';
import { numberOf, ProcessModelFormFieldsetProps } from './util';
import { FormNumberInput, FormSelectInput } from './Inputs';
import { ProcessModelTarget } from './userInput';
import { fmtNumber } from './CalculatedValues';

type TargetFieldsProps = ProcessModelFormFieldsetProps<
  'solveTarget' | 'P_R_user' | 'pH_R_user',
  'P_R_suggested' | 'pH_R_suggested'
>;

export const TargetFields: FC<TargetFieldsProps> = memo(
  ({ set, solveTarget, P_R_user, pH_R_user, P_R_suggested, pH_R_suggested }) => {
    return (
      <Fieldset title="Targets">
        <Grid item xs={12} sm={6}>
          <FormSelectInput
            label="Target"
            value={solveTarget ?? ''}
            onChange={(ev) => {
              set('solveTarget', ev.target.value as ProcessModelTarget);
            }}
          >
            <MenuItem value={ProcessModelTarget.PH_R}>pH</MenuItem>
            <MenuItem value={ProcessModelTarget.REM_P}>P Removal</MenuItem>
          </FormSelectInput>
        </Grid>
        <Grid item xs={12} sm={6}>
          {solveTarget === ProcessModelTarget.PH_R && (
            <FormNumberInput
              label="pH"
              value={pH_R_user ?? ''}
              placeholder={fmtNumber(pH_R_suggested)}
              onChange={(ev) => set('pH_R_user', numberOf(ev.target.value))}
            />
          )}
          {solveTarget === ProcessModelTarget.REM_P && (
            <FormNumberInput
              label="Effluent P Target (mg/L)"
              value={P_R_user ?? ''}
              placeholder={fmtNumber(P_R_suggested)}
              onChange={(ev) => set('P_R_user', numberOf(ev.target.value))}
            />
          )}
        </Grid>
      </Fieldset>
    );
  },
);
