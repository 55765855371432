import React, { useContext, createContext, FC, useCallback } from 'react';
import { CognitoUserInterface } from '@aws-amplify/ui-components';

interface AuthProvider {
  user: CognitoUserInterface | null;
  isAdmin: boolean;
  getProviderName?: (user: CognitoUserInterface) => string | null;
}

const AuthContext = createContext<AuthProvider>({
  user: null,
  isAdmin: false,
  getProviderName: () => {
    return null;
  },
});

const AuthContextProvider: FC<AuthProvider> = ({ user, isAdmin, children }) => {
  const getProviderName = useCallback((user) => {
    let identities;
    if (user) {
      identities = user.attributes.identities ? JSON.parse(user.attributes.identities)[0] : null;
    }
    return user && identities?.providerName ? identities.providerName : null;
  }, []);

  const contextValue = {
    user,
    isAdmin,
    getProviderName,
  };
  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;

export const useAuthContext = () => {
  const { user, isAdmin, getProviderName } = useContext(AuthContext);

  if (!user) throw new Error('missing User in AuthContext.');

  return {
    user,
    isAdmin,
    getProviderName,
  };
};
