import React, { ButtonHTMLAttributes, FC, MouseEvent } from 'react';
import { Auth } from 'aws-amplify';
import UnStyledButton from '../base/UnStyledButton';
import { useHistory } from 'react-router';

const SignOut: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, ...props }) => {
  const history = useHistory();

  async function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    try {
      await Auth.signOut();
      history.push('/');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  return (
    <UnStyledButton onClick={handleClick} {...props}>
      {children}
    </UnStyledButton>
  );
};

export default SignOut;
