import React, { FC, memo, useMemo } from 'react';
import Qty from 'js-quantities';
import { Grid, MenuItem, TableCell } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import { Fieldset } from '../Fieldset';
import { FormNumberInput, FormSelectInput } from './Inputs';
import { CalculatedNumber, CalculatedNumberLabel } from './CalculatedValues';
import { numberOf, ProcessModelFormFieldsetProps } from './util';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { FlowType } from './userInput';

type ReactorFeedDataFieldsProps = ProcessModelFormFieldsetProps<
  | 'flowType_1'
  | 'flowType_2'
  | 'flowLabel_1'
  | 'flowLabel_2'
  | 'flowUnitWeb'
  | 'Q_1'
  | 'Q_2'
  | 'P_1'
  | 'P_2'
  | 'N_1'
  | 'N_2'
  | 'Mg_1_user'
  | 'Mg_2_user'
  | 'Ca_1_user'
  | 'Ca_2_user'
  | 'Na_1_user'
  | 'Na_2_user'
  | 'pH_1_user'
  | 'pH_2_user'
  | 'Alk_1_user'
  | 'Alk_2_user'
  | 'Cond_1_user'
  | 'Cond_2_user'
  | 'TSS_1_user'
  | 'TSS_2_user',
  // Computed ⬇
  | 'Mg_1_suggested'
  | 'Mg_2_suggested'
  | 'Ca_1_suggested'
  | 'Ca_2_suggested'
  | 'Na_1_suggested'
  | 'Na_2_suggested'
  | 'pH_1_suggested'
  | 'pH_2_suggested'
  | 'Alk_1_suggested'
  | 'Alk_2_suggested'
  | 'Cond_1_suggested'
  | 'Cond_2_suggested'
  | 'Q_In'
  | 'P_mgL_In'
  | 'N_mgL_In'
  | 'Mg_mgL_In'
  | 'Ca_mgL_In'
  | 'Na_mgL_In'
  | 'pH_In'
  | 'Alk_mgL_In'
  | 'P_Loading_Rate_1'
  | 'P_Loading_Rate_2'
  | 'P_Loading_Rate_In'
  | 'Cond_In'
  | 'TSS_1_suggested'
  | 'TSS_2_suggested'
  | 'TSS_mgL_In'
>;

const feedStream1Options = [
  'Post-Digestion Liquor',
  'Post-Digestion Centrate',
  'Centrate',
  'Lagoon Supernatant',
  'Post-Digestion Filtrate',
  'BFP Filtrate',
  'Combined Feed',
];

const feedStream2Options = [
  'Not Required',
  'Pre-Digestion Liquor',
  'Wasstripate',
  'WASSTRIP Centrate',
  'WASSTRIP Filtrate',
  'GBT Filtrate',
  'BFP Filtrate',
  'RDT Filtrate',
  'DAF Subnatant',
];

export const ReactorFeedDataFields: FC<ReactorFeedDataFieldsProps> = memo(
  ({
    set,
    flowType_1,
    flowType_2,
    flowLabel_1,
    flowLabel_2,
    flowUnitWeb,
    Q_1,
    Q_2,
    Q_In,
    P_1,
    P_2,
    P_mgL_In,
    P_Loading_Rate_1,
    P_Loading_Rate_2,
    P_Loading_Rate_In,
    N_1,
    N_2,
    N_mgL_In,
    Mg_1_user,
    Mg_1_suggested,
    Mg_2_user,
    Mg_2_suggested,
    Mg_mgL_In,
    Ca_1_user,
    Ca_1_suggested,
    Ca_2_user,
    Ca_2_suggested,
    Ca_mgL_In,
    Na_1_user,
    Na_1_suggested,
    Na_2_user,
    Na_2_suggested,
    Na_mgL_In,
    pH_1_user,
    pH_1_suggested,
    pH_2_user,
    pH_2_suggested,
    pH_In,
    Alk_1_user,
    Alk_1_suggested,
    Alk_2_user,
    Alk_2_suggested,
    Alk_mgL_In,
    Cond_1_user,
    Cond_1_suggested,
    Cond_2_user,
    Cond_2_suggested,
    Cond_In,
    TSS_1_user,
    TSS_2_user,
    TSS_1_suggested,
    TSS_2_suggested,
    TSS_mgL_In,
  }) => {
    const convertFlow = useMemo(() => Qty.swiftConverter('L/min', flowUnitWeb), [flowUnitWeb]);

    // % Influent TP B26 is not shown: needs unused fields

    const flowTypeOpts = useMemo(
      () =>
        Object.values(FlowType).map((type) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        )),
      [],
    );

    return (
      <Fieldset title="Reactor Feed Data">
        <Grid item xs>
          <TableContainer>
            <Table size="small" style={{ tableLayout: 'fixed', minWidth: 600 }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '20%' }}>Unit</TableCell>
                  <TableCell>
                    <Autocomplete
                      freeSolo
                      autoSelect
                      disableClearable
                      options={feedStream1Options}
                      value={flowLabel_1}
                      onChange={(_, newInputValue) => set('flowLabel_1', newInputValue)}
                      renderInput={(params) => (
                        <TextField {...params} aria-label="Pearl Feed Stream I" />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      freeSolo
                      autoSelect
                      disableClearable
                      options={feedStream2Options}
                      value={flowLabel_2}
                      onChange={(_, newInputValue) => set('flowLabel_2', newInputValue)}
                      renderInput={(params) => (
                        <TextField {...params} aria-label="Pearl Feed Stream II" />
                      )}
                    />
                  </TableCell>
                  <TableCell style={{ width: '25%' }}>Combined Feed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <FormSelectInput
                      value={flowUnitWeb}
                      onChange={(ev) => set('flowUnitWeb', ev.target.value as string)}
                    >
                      <MenuItem value="gal/d">Imperial</MenuItem>
                      <MenuItem value="m^3/d">Metric</MenuItem>
                    </FormSelectInput>
                  </TableCell>
                  <TableCell>
                    <FormSelectInput
                      label="Stream Preset"
                      value={flowType_1}
                      onChange={(ev) => set('flowType_1', ev.target.value as FlowType)}
                    >
                      {flowTypeOpts}
                    </FormSelectInput>
                  </TableCell>
                  <TableCell>
                    <FormSelectInput
                      label="Stream Preset"
                      value={flowType_2}
                      onChange={(ev) => set('flowType_2', ev.target.value as FlowType)}
                    >
                      {flowTypeOpts}
                    </FormSelectInput>
                  </TableCell>
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell>{flowUnitWeb === 'gal/d' ? 'gpd' : 'm3/d'}</TableCell>
                  <TableCell>
                    <FormNumberInput
                      label="Flow"
                      value={Q_1 ?? ''}
                      onChange={(ev) => set('Q_1', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <FormNumberInput
                      label="Flow"
                      value={Q_2 ?? ''}
                      onChange={(ev) => set('Q_2', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <CalculatedNumber value={convertFlow(Q_In)} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>mg PO4-P/L</TableCell>
                  <TableCell>
                    <FormNumberInput
                      label="P Concentration"
                      value={P_1 ?? ''}
                      onChange={(ev) => set('P_1', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <FormNumberInput
                      label="P Concentration"
                      value={P_2 ?? ''}
                      onChange={(ev) => set('P_2', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <CalculatedNumber value={P_mgL_In} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>kg PO4-P/day</TableCell>
                  <TableCell>
                    <CalculatedNumberLabel label="P Loading Rate" value={P_Loading_Rate_1} />
                  </TableCell>
                  <TableCell>
                    <CalculatedNumberLabel label="P Loading Rate" value={P_Loading_Rate_2} />
                  </TableCell>
                  <TableCell>
                    <CalculatedNumber value={P_Loading_Rate_In} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>mg NH3-N/L</TableCell>
                  <TableCell>
                    <FormNumberInput
                      label="N Concentration"
                      value={N_1 ?? ''}
                      onChange={(ev) => set('N_1', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <FormNumberInput
                      label="N Concentration"
                      value={N_2 ?? ''}
                      onChange={(ev) => set('N_2', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <CalculatedNumber value={N_mgL_In} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>mg/L</TableCell>
                  <TableCell>
                    <FormNumberInput
                      label="Mg Concentration"
                      value={Mg_1_user ?? ''}
                      placeholder={Mg_1_suggested?.toString() ?? ''}
                      onChange={(ev) => set('Mg_1_user', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <FormNumberInput
                      label="Mg Concentration"
                      value={Mg_2_user ?? ''}
                      placeholder={Mg_2_suggested?.toString() ?? ''}
                      onChange={(ev) => set('Mg_2_user', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <CalculatedNumber value={Mg_mgL_In} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>mg/L</TableCell>
                  <TableCell>
                    <FormNumberInput
                      label="Ca Concentration"
                      value={Ca_1_user ?? ''}
                      placeholder={Ca_1_suggested?.toString() ?? ''}
                      onChange={(ev) => set('Ca_1_user', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <FormNumberInput
                      label="Ca Concentration"
                      value={Ca_2_user ?? ''}
                      placeholder={Ca_2_suggested?.toString() ?? ''}
                      onChange={(ev) => set('Ca_2_user', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <CalculatedNumber value={Ca_mgL_In} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>mg/L</TableCell>
                  <TableCell>
                    <FormNumberInput
                      label="Na Concentration"
                      value={Na_1_user ?? ''}
                      placeholder={Na_1_suggested?.toString() ?? ''}
                      onChange={(ev) => set('Na_1_user', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <FormNumberInput
                      label="Na Concentration"
                      value={Na_2_user ?? ''}
                      placeholder={Na_2_suggested?.toString() ?? ''}
                      onChange={(ev) => set('Na_2_user', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <CalculatedNumber value={Na_mgL_In} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>SU</TableCell>
                  <TableCell>
                    <FormNumberInput
                      label="pH"
                      value={pH_1_user ?? ''}
                      placeholder={pH_1_suggested?.toString() ?? ''}
                      onChange={(ev) => set('pH_1_user', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <FormNumberInput
                      label="pH"
                      value={pH_2_user ?? ''}
                      placeholder={pH_2_suggested?.toString() ?? ''}
                      onChange={(ev) => set('pH_2_user', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <CalculatedNumber value={pH_In} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>mg/L</TableCell>
                  <TableCell>
                    <FormNumberInput
                      label="TSS"
                      value={TSS_1_user ?? ''}
                      placeholder={TSS_1_suggested?.toString() ?? ''}
                      onChange={(ev) => set('TSS_1_user', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <FormNumberInput
                      label="TSS"
                      value={TSS_2_user ?? ''}
                      placeholder={TSS_2_suggested?.toString() ?? ''}
                      onChange={(ev) => set('TSS_2_user', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <CalculatedNumber value={TSS_mgL_In} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>mg CaCO3/L</TableCell>
                  <TableCell>
                    <FormNumberInput
                      label="Alkalinity"
                      value={Alk_1_user ?? ''}
                      placeholder={Alk_1_suggested?.toString() ?? ''}
                      onChange={(ev) => set('Alk_1_user', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <FormNumberInput
                      label="Alkalinity"
                      value={Alk_2_user ?? ''}
                      placeholder={Alk_2_suggested?.toString() ?? ''}
                      onChange={(ev) => set('Alk_2_user', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <CalculatedNumber value={Alk_mgL_In} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>µS/cm</TableCell>
                  <TableCell>
                    <FormNumberInput
                      label="Conductivity"
                      value={Cond_1_user ?? ''}
                      placeholder={Cond_1_suggested?.toString() ?? ''}
                      onChange={(ev) => set('Cond_1_user', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <FormNumberInput
                      label="Conductivity"
                      value={Cond_2_user ?? ''}
                      placeholder={Cond_2_suggested?.toString() ?? ''}
                      onChange={(ev) => set('Cond_2_user', numberOf(ev.target.value))}
                    />
                  </TableCell>
                  <TableCell>
                    <CalculatedNumber value={Cond_In} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Fieldset>
    );
  },
);
