import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import React from 'react';

const useBtnStyles = makeStyles(() => ({
  button: {
    borderRadius: '0',
  },
}));

const STButton = ((props: ButtonProps) => {
  const classes = useBtnStyles();
  return <Button color="secondary" variant="contained" className={classes.button} {...props} />;
}) as typeof Button; // needed to make  the `component` prop work

export default STButton;
