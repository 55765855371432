import Qty from 'js-quantities';
import { RequiredProcessModelConstants } from './processModelConstants';
import { UnreachableCaseError } from '../../lib/UnreachableCaseError';

export interface RawUserProcessModelInput {
  // General
  plantName?: string;
  plantOwner?: string;

  // Feed 1 & 2
  flowLabel_1: string;
  flowLabel_2: string;
  flowType_1: FlowType;
  flowType_2: FlowType;
  flowUnitWeb: string; // gal/d or m^3/d
  Q_1?: number; // [flowUnitWeb]
  Q_2?: number; // [flowUnitWeb]
  Mg_1_user?: number; // [mg/L Mg
  Mg_2_user?: number; // [mg/L Mg
  Ca_1_user?: number; // mg/L Ca
  Ca_2_user?: number; // mg/L Ca
  N_1?: number; // mg/L NH3-N
  N_2?: number; // mg/L NH3-N
  P_1?: number; // mg/L PO4-P
  P_2?: number; // mg/L PO4-P
  Na_1_user?: number; // mg/L Na
  Na_2_user?: number; // mg/L Na
  pH_1_user?: number;
  pH_2_user?: number;
  Alk_1_user?: number; // mg/L CaCO3
  Alk_2_user?: number; // mg/L CaCO3
  Cond_1_user?: number; //µS/cm
  Cond_2_user?: number; // µS/cm

  // Reactor Model (Rows 37 - 39)
  reactorModel?: ReactorModel;
  Qty_reactor_user?: number; // scalar

  // Reactor Data (Rows 42 - 47)
  MgP_A?: number; // activity Mg:P Ratio [mol Mg/mol P]
  mgType?: MagnesiumType;

  // Chemical data (Rows 49 - 51)
  ww_MgCl2_Neat?: number; // Neat MgCl2 Concentration (w/w)
  ww_NaOH_Neat?: number; // Neat NaOH Concentration (w/w)

  // Feed Stream Acid Dosing
  feedStreamForDosing: FeedStreamSelection;
  Acid_pHDrop?: number;
  Acid_kmolHLSU_user?: number;

  // Targets
  solveTarget: ProcessModelTarget;
  P_R_user?: number; // new input, not in excel
  pH_R_user?: number; // pH

  // Misc
  Days_Ops_user?: number;

  // N dosing
  NP_Add_YN: 'Y' | 'N';
  NP_D_user?: number; // Desired N:P  Ratio [mol N/mol P] Only available if NP_Add_YN = 'Y'
}

export interface RawUserSecondaryInput {
  TSS_1_user?: number; // mg/L
  TSS_2_user?: number; // mg/L
  currency?: string;
  Operating_Labour_Cost?: number;
  Electricity_Cost?: number;
  metalSalt?: MetalSaltType;
  MetalSalt_Unit: string;
  MetalSalt_Cost?: number;
  MetalSalt_Solution_Concentration?: number;
  Metal_P_Ratio?: number;
  Alk_Consumption_Required: string;
  NaOH_Operating_Unit: string;
  NaOH_Operating_Cost?: number;
  Polymer_Use?: number;
  Polymer_Cost?: number;
  Sludge_Disposal_Cost?: number;
  Cake_Solids?: number;
  Plant_Sludge_Production?: number;
  WASSTRIP_Cake_Solids_Improvement?: number;
  WASSTRIP_Polymer_Reduction?: number;
  Ammonia_Removal_Cost?: number;
  Crystal_Green_Offtake_Price?: number;
  Dryer_Type: string;
  Qty_dryer?: number | null;
  Natural_Gas_Cost?: number;
  Hot_Water_Cost?: number;
  Mg_Operating_Unit?: string;
  Mg_Operating_Cost?: number;
  ammonia?: AmmoniaType;
  Ammonia_Unit: string;
  Ammonia_Cost?: number;
  acid?: AcidType;
  Acid_Unit: string;
  Acid_Cost?: number;
  Lab_Hatch_Cost?: number;
  Pallets_Unit_Cost?: number;
  Bags_Unit_Cost?: number;
  Product_Handling_Requested: string;
}

// User input as stored on the database
export type RawUserInput = RawUserProcessModelInput & RawUserSecondaryInput;

export type FeedStreamSelection = 'feed1' | 'feed2' | 'not_used';

export enum ProcessModelTarget {
  REM_P = 'Rem_P',
  PH_R = 'pH_R',
}

export enum FlowType {
  EMPTY = 'Not Used',
  OTHER = 'Other Stream',
  POST_DIGESTION = 'Post Digestion Stream',
  WASSTRIP = 'WASSTRIP stream',
}

export type ReactorModel = {
  name: string;
  mixingZoneDiameter_Inches: number;
  upflowVelocity_CmMin: number;
  recirculationPump: number;
  airCompressor: number;
} & (
  | {
      family: 'Pearl';
      pearl_reactorDesignLoad_KgOfP_Day?: number | null;
      pearl_suggestionQtyThreshold?: number | null;
    }
  | { family: 'PearlFx'; pearlFx_nominalFlow_M3_Day?: number | null }
);

export interface MagnesiumType {
  id: string;
  label: string;
  causticOffset: number;
  mg_P_Activity_Multiplier: number;
}

export interface MetalSaltType {
  id: string;
  symbol_label: string;
  // metalSalt: string;
  metal: string;
  // molar_weight: number;
  // kg_sludge_kg_P_removed_formula: string;
}

export interface AmmoniaType {
  id: string;
  symbol_label: string;
  concentration_ww: number;
  density_kg_l: number;
  molecular_weight_g_mol: number;
  number_of_mols_n_molecule: number;
}

export interface AcidType {
  id: string;
  symbol_label: string;
  default: boolean;
}

// Normalized input view for easier runtime input handling
export interface UserNormalizedInput {
  unitSystem: 'metric' | 'imperial';

  // Reactor Model properties (lookup based on reactor Model Selected)
  reactorModel: ReactorModel | null;
  Qty_reactor_user: number | null;
  D_Z1_in: number;
  Upflow_Z1: number;

  // Chemical data
  ww_MgCl2_Neat: number;
  ww_NaOH_Neat: number;

  // Reactor data
  MgP_A: number;
  mgType: MagnesiumType | null;

  // Feed 1
  flowType_1: FlowType;
  Q_1_M3_D: number;
  Q_1: number;
  Mg_1_user: number | null;
  N_1: number;
  P_1: number;
  Ca_1_user: number | null;
  Na_1_user: number | null;
  pH_1_user: number | null;
  Alk_1_user: number | null;
  Cond_1_user: number | null;
  TSS_1_user: number | null;

  // Feed 2
  flowType_2: FlowType;
  Q_2_M3_D: number;
  Q_2: number;
  Mg_2_user: number | null;
  N_2: number;
  P_2: number;
  Ca_2_user: number | null;
  Na_2_user: number | null;
  pH_2_user: number | null;
  Alk_2_user: number | null;
  Cond_2_user: number | null;
  TSS_2_user: number | null;

  // Targets
  P_R_user: number | null;
  pH_R_user: number | null;
  solveTarget: ProcessModelTarget;

  Mg_NaOH_Offset: number;
  feedStreamForDosing: FeedStreamSelection;
  Acid_pHDrop: number;
  Acid_kmolHLSU_user: number | null;

  // N dosing
  NP_D_user: number | null;
  ww_N_Add: number;
  ro_N_Add: number;
  AW_N_Add: number;
  molN_N_Add: number;

  Days_Ops_user: number | null;
  Operating_Labour_Cost: number | null;
  Electricity_Cost: number | null;
  currency: string | null;
  metalSalt: MetalSaltType | null;
  MetalSalt_Unit: string;
  MetalSalt_Cost: number | null;
  MetalSalt_Solution_Concentration: number | null;
  Metal_P_Ratio: number | null;
  Alk_Consumption_Required: string;
  NaOH_Operating_Unit: string;
  NaOH_Operating_Cost: number | null;
  Polymer_Use: number | null;
  Polymer_Cost: number | null;
  Sludge_Disposal_Cost: number | null;
  Cake_Solids: number | null;
  Plant_Sludge_Production: number | null;
  WASSTRIP_Cake_Solids_Improvement: number | null;
  WASSTRIP_Polymer_Reduction: number | null;
  Ammonia_Removal_Cost: number | null;
  Crystal_Green_Offtake_Price: number | null;
  Dryer_Type: string;
  Qty_dryer: number | null;
  Natural_Gas_Cost: number | null;
  Hot_Water_Cost: number | null;
  Mg_Operating_Unit: string | null;
  Mg_Operating_Cost: number | null;
  ammonia: AmmoniaType | null;
  Ammonia_Unit: string;
  Ammonia_Cost: number | null;
  acid: AcidType | null;
  Acid_Unit: string;
  Acid_Cost: number | null;
  Lab_Hatch_Cost: number | null;
  Pallets_Unit_Cost: number | null;
  Bags_Unit_Cost: number | null;
  Product_Handling_Requested: string;
}

export default function normalizedUserInputOf({
  reactorModel,
  Qty_reactor_user,
  flowUnitWeb,
  flowType_1,
  flowType_2,
  mgType,
  feedStreamForDosing,
  Q_1 = 0,
  Q_2 = 0,
  pH_1_user,
  pH_2_user,
  Alk_1_user,
  Alk_2_user,
  Cond_1_user,
  Cond_2_user,
  Ca_1_user,
  Ca_2_user,
  MgP_A = 0,
  Mg_1_user,
  Mg_2_user,
  N_1 = 0,
  N_2 = 0,
  Na_1_user,
  Na_2_user,
  P_1 = 0,
  P_2 = 0,
  solveTarget,
  P_R_user,
  pH_R_user,
  ww_MgCl2_Neat = 0,
  ww_NaOH_Neat = 0,
  Acid_pHDrop = 0,
  Acid_kmolHLSU_user,
  Days_Ops_user,
  TSS_1_user,
  TSS_2_user,
  Operating_Labour_Cost,
  Electricity_Cost,
  currency,
  metalSalt,
  MetalSalt_Cost,
  MetalSalt_Unit,
  MetalSalt_Solution_Concentration,
  Metal_P_Ratio,
  Alk_Consumption_Required,
  NaOH_Operating_Unit,
  NaOH_Operating_Cost,
  Polymer_Use,
  Polymer_Cost,
  Sludge_Disposal_Cost,
  Cake_Solids,
  Plant_Sludge_Production,
  WASSTRIP_Cake_Solids_Improvement,
  WASSTRIP_Polymer_Reduction,
  Ammonia_Removal_Cost,
  Crystal_Green_Offtake_Price,
  Dryer_Type,
  Qty_dryer,
  Natural_Gas_Cost,
  Hot_Water_Cost,
  Mg_Operating_Unit,
  Mg_Operating_Cost,
  ammonia,
  Ammonia_Unit,
  Ammonia_Cost,
  acid,
  Acid_Unit,
  Acid_Cost,
  Lab_Hatch_Cost,
  Pallets_Unit_Cost,
  Bags_Unit_Cost,
  Product_Handling_Requested,
  NP_D_user,
}: RawUserInput): UserNormalizedInput {
  const flowToLiterMin = Qty.swiftConverter(flowUnitWeb, 'L/min');
  const flowToM3Day = Qty.swiftConverter(flowUnitWeb, 'm^3/day');

  return {
    unitSystem: flowUnitWeb === 'gal/d' ? 'imperial' : 'metric',
    flowType_1,
    flowType_2,
    Q_1_M3_D: flowToM3Day(Q_1),
    Q_2_M3_D: flowToM3Day(Q_2),
    Q_1: flowToLiterMin(Q_1),
    Q_2: flowToLiterMin(Q_2),
    reactorModel: reactorModel ?? null,
    D_Z1_in: reactorModel?.mixingZoneDiameter_Inches ?? 0,
    Upflow_Z1: reactorModel?.upflowVelocity_CmMin ?? 0,
    Qty_reactor_user: Qty_reactor_user ?? null,
    pH_1_user: pH_1_user ?? null,
    pH_2_user: pH_2_user ?? null,
    Alk_1_user: Alk_1_user ?? null,
    Alk_2_user: Alk_2_user ?? null,
    Cond_1_user: Cond_1_user ?? null,
    Cond_2_user: Cond_2_user ?? null,
    Ca_1_user: Ca_1_user ?? null,
    Ca_2_user: Ca_2_user ?? null,
    MgP_A,
    Mg_1_user: Mg_1_user ?? null,
    Mg_2_user: Mg_2_user ?? null,
    N_1,
    N_2,
    Na_1_user: Na_1_user ?? null,
    Na_2_user: Na_2_user ?? null,
    P_1,
    P_2,
    solveTarget,
    P_R_user: P_R_user ?? null,
    pH_R_user: pH_R_user ?? null,
    ww_MgCl2_Neat,
    ww_NaOH_Neat,
    mgType: mgType ?? null,
    Mg_NaOH_Offset: mgType?.causticOffset ?? 0,
    feedStreamForDosing,
    Acid_pHDrop,
    Acid_kmolHLSU_user: Acid_kmolHLSU_user ?? null,
    Days_Ops_user: Days_Ops_user ?? null,
    TSS_1_user: TSS_1_user ?? null,
    TSS_2_user: TSS_2_user ?? null,
    Operating_Labour_Cost: Operating_Labour_Cost ?? null,
    Electricity_Cost: Electricity_Cost ?? null,
    currency: currency ?? null,
    metalSalt: metalSalt ?? null,
    MetalSalt_Cost: MetalSalt_Cost ?? null,
    MetalSalt_Unit: MetalSalt_Unit,
    MetalSalt_Solution_Concentration: MetalSalt_Solution_Concentration ?? null,
    Metal_P_Ratio: Metal_P_Ratio ?? null,
    Alk_Consumption_Required,
    NaOH_Operating_Unit,
    NaOH_Operating_Cost: NaOH_Operating_Cost ?? null,
    Polymer_Use: Polymer_Use ?? null,
    Polymer_Cost: Polymer_Cost ?? null,
    Sludge_Disposal_Cost: Sludge_Disposal_Cost ?? null,
    Cake_Solids: Cake_Solids ?? null,
    Plant_Sludge_Production: Plant_Sludge_Production ?? null,
    WASSTRIP_Cake_Solids_Improvement: WASSTRIP_Cake_Solids_Improvement ?? null,
    WASSTRIP_Polymer_Reduction: WASSTRIP_Polymer_Reduction ?? null,
    Ammonia_Removal_Cost: Ammonia_Removal_Cost ?? null,
    Crystal_Green_Offtake_Price: Crystal_Green_Offtake_Price ?? null,
    Dryer_Type: Dryer_Type,
    Qty_dryer: Qty_dryer ?? null,
    Natural_Gas_Cost: Natural_Gas_Cost ?? null,
    Hot_Water_Cost: Hot_Water_Cost ?? null,
    Mg_Operating_Unit: Mg_Operating_Unit ?? null,
    Mg_Operating_Cost: Mg_Operating_Cost ?? null,
    ammonia: ammonia ?? null,
    Ammonia_Unit: Ammonia_Unit ?? null,
    Ammonia_Cost: Ammonia_Cost ?? null,
    acid: acid ?? null,
    Acid_Unit,
    Acid_Cost: Acid_Cost ?? null,
    Lab_Hatch_Cost: Lab_Hatch_Cost ?? null,
    Pallets_Unit_Cost: Pallets_Unit_Cost ?? null,
    Bags_Unit_Cost: Bags_Unit_Cost ?? null,
    Product_Handling_Requested,
    NP_D_user: NP_D_user ?? null,
    ww_N_Add: ammonia?.concentration_ww ?? 0,
    ro_N_Add: ammonia?.density_kg_l ?? 0,
    AW_N_Add: ammonia?.molecular_weight_g_mol ?? 0,
    molN_N_Add: ammonia?.number_of_mols_n_molecule ?? 0,
  };
}

export type ProcessModelComputedInput = {
  Mg_1: number;
  Mg_2: number;
  Ca_1: number;
  Ca_2: number;
  Na_1: number;
  Na_2: number;
  pH_1: number;
  pH_2: number;
  Alk_1: number;
  Alk_2: number;
  Cond_1: number;
  Cond_2: number;

  Q_2: number;
  Q_In: number;
  Mg_mgL_In: number;
  N_mgL_In: number;
  P_mgL_In: number;
  Ca_mgL_In: number;
  Na_mgL_In: number;
  Alk_mgL_In: number;

  Mg_InA: number;
  N_InA: number;
  P_In: number;
  Ca_In: number;
  Na_InA: number;
  Alk_In: number;
  Cond_In: number;

  // Chemical data
  Q_MgCl2_Neat: number;
  Mg_MgCl2_Neat: number;
  ww_NaOH_Neat: number;
  Mg_mgL_InA: number;
  Q_MgCl2_Neat_reactor: number;

  // reactor
  Qty_reactor: number;
  D_Z1_in: number;
  Upflow_Z1: number;

  // reactor data
  MgP_A: number;
  MgP_D: number;

  // combined influent items
  pH_In: number;
  MgP_In: number;

  // inputs
  solveTarget: 'Rem_P' | 'pH_R';
  pH_R: number;
  Rem_P: number;

  // 'Process Model' - Recipe Data Inputs - Calculations & Lookups
  kgd_P: number;
  kgd_P_reactor: number;

  // Feed Stream Acid Dosing
  Q: number;
  Acid_pHDrop: number;
  Acid_kmolHLSU: number;

  // Caustic use
  Mg_NaOH_Offset: number;

  /// Additions to the required inputs follow

  Mg_1_suggested: number | null;
  Ca_1_suggested: number | null;
  Na_1_suggested: number | null;
  Alk_1_suggested: number | null;
  pH_1_suggested: number | null;
  Cond_1_suggested: number | null;
  Mg_2_suggested: number | null;
  Ca_2_suggested: number | null;
  Na_2_suggested: number | null;
  Alk_2_suggested: number | null;
  pH_2_suggested: number | null;
  Cond_2_suggested: number | null;

  P_Loading_Rate_1: number;
  P_Loading_Rate_2: number;
  P_Loading_Rate_In: number;

  Pearl_Reactor_Suggestion: string | null;
  Qty_Pearl_Reactor_Suggestion: number | null;
  Usage_Pearl_Reactor_Suggestion: number | null;

  Pearl_Fx_Reactor_Suggestion: string | null;
  Qty_Pearl_Fx_Reactor_Suggestion: number | null;
  Usage_Pearl_Fx_Reactor_Suggestion: number | null;
  Pearl_Fx_Max_Concentration: number;

  Qty_reactor_suggested: number | null;
  Usage_reactor: number | null;

  Acid_kmolHLSU_suggested: number;
  P_R_suggested: number;
  pH_R_suggested: number;

  Days_Ops_suggested: number;
  Days_Ops: number;

  TSS_1_suggested: number | null;
  TSS_1: number;
  TSS_2_suggested: number | null;
  TSS_2: number;
  TSS_mgL_In: number;

  NP: number;
  NP_D_suggested: number | null;
  NP_D: number;
  ww_N_Add: number;
  ro_N_Add: number;
  AW_N_Add: number;
  molN_N_Add: number;
};

export function processModelComputedInputOf(
  constants: RequiredProcessModelConstants,
  reactors: ReactorModel[],
  input: UserNormalizedInput,
): ProcessModelComputedInput {
  const {
    AWMg,
    AWN,
    AWP,
    AWCa,
    AWNa,
    MWAlk,
    MWMgCl2,
    NP_Desired_suggested: NP_D_suggested = null,
    Pearl_Fx_Max_Concentration = 400,
  } = constants;

  const {
    flowType_1,
    flowType_2,
    Q_1_M3_D,
    Q_2_M3_D,
    Q_1,
    Q_2,
    Mg_1_user,
    Mg_2_user,
    N_1,
    N_2,
    P_1,
    P_2,
    Ca_1_user,
    Ca_2_user,
    Na_1_user,
    Na_2_user,
    Alk_1_user,
    Alk_2_user,
    pH_1_user,
    pH_2_user,
    Cond_1_user,
    Cond_2_user,
    ww_MgCl2_Neat,
    MgP_A,
    Mg_NaOH_Offset,
    reactorModel,
    Qty_reactor_user,
    Acid_kmolHLSU_user,
    solveTarget,
    pH_R_user,
    P_R_user,
    Days_Ops_user,
    TSS_1_user,
    TSS_2_user,
    mgType,
    NP_D_user,
  } = input;

  // Defaults by stream type
  const {
    Mg: Mg_1_suggested,
    Ca: Ca_1_suggested,
    Na: Na_1_suggested,
    Alk: Alk_1_suggested,
    pH: pH_1_suggested,
    Cond: Cond_1_suggested,
  } = suggestedFeedValues(flowType_1);

  const {
    Mg: Mg_2_suggested,
    Ca: Ca_2_suggested,
    Na: Na_2_suggested,
    Alk: Alk_2_suggested,
    pH: pH_2_suggested,
    Cond: Cond_2_suggested,
  } = suggestedFeedValues(flowType_2);

  const Mg_1 = Mg_1_user ?? Mg_1_suggested ?? 0;
  const Ca_1 = Ca_1_user ?? Ca_1_suggested ?? 0;
  const Na_1 = Na_1_user ?? Na_1_suggested ?? 0;
  const Alk_1 = Alk_1_user ?? Alk_1_suggested ?? 0;
  const pH_1 = pH_1_user ?? pH_1_suggested ?? 0;
  const Cond_1 = Cond_1_user ?? Cond_1_suggested ?? 0;
  const Mg_2 = Mg_2_user ?? Mg_2_suggested ?? 0;
  const Ca_2 = Ca_2_user ?? Ca_2_suggested ?? 0;
  const Na_2 = Na_2_user ?? Na_2_suggested ?? 0;
  const Alk_2 = Alk_2_user ?? Alk_2_suggested ?? 0;
  const pH_2 = pH_2_user ?? pH_2_suggested ?? 0;
  const Cond_2 = Cond_2_user ?? Cond_2_suggested ?? 0;

  // Reactor Data (Rows 42 - 47)
  //  Dosing Mg:P Ratio [mol Mg/mol P] = Activity MgP *1.2 for MgO Dosing and *1 for MgCl2
  const MgP_D = MgP_A * (mgType?.mg_P_Activity_Multiplier ?? 1);

  //  'Process Model' - Recipe Data Inputs - Calculations & Lookups
  const kgd_P = ((Q_1 * P_1 + Q_2 * P_2) * 24 * 60) / 10 ** 6; //  (B6) Calculated Total P Loading Rate kg P/day

  //  'Inflows' Tab of Excel Model
  //  Combined Feed 1 & 2 Concentrations
  const Q_In = Q_1 + Q_2;
  const Mg_mgL_In = (Q_1 * Mg_1 + Q_2 * Mg_2) / Q_In;
  const N_mgL_In = (Q_1 * N_1 + Q_2 * N_2) / Q_In;
  const P_mgL_In = (Q_1 * P_1 + Q_2 * P_2) / Q_In;
  const Ca_mgL_In = (Q_1 * Ca_1 + Q_2 * Ca_2) / Q_In;
  const Na_mgL_In = (Q_1 * Na_1 + Q_2 * Na_2) / Q_In;
  const Alk_mgL_In = (Q_1 * Alk_1 + Q_2 * Alk_2) / Q_In;
  const Cond_In = (Q_1 * Cond_1 + Q_2 * Cond_2) / Q_In;

  const Q_In_M3_D = Q_1_M3_D + Q_2_M3_D;
  const P_Loading_Rate_1 = (Q_1_M3_D * P_1) / 1000;
  const P_Loading_Rate_2 = (Q_2_M3_D * P_2) / 1000;
  const P_Loading_Rate_In = P_Loading_Rate_1 + P_Loading_Rate_2;

  //  Additional Combined Influent Items (Calculated on the Pearl
  //  Inputs Page) (NEW 2/11)
  const pH_In = (Q_1 * pH_1 + Q_2 * pH_2) / Q_In; //  (Pearl Inputs F31) Combined Influent pH (NEW)
  const MgP_In = Mg_mgL_In / AWMg / (P_mgL_In / AWP); //  (Pearl Inputs D41) Actual Mg:P Ratio [mol/mol] (NEW)

  // Inputs for Process Model
  const Mg_InA = Mg_mgL_In / AWMg / 1000;
  const N_InA = N_mgL_In / AWN / 1000;
  const P_In = P_mgL_In / AWP / 1000;
  const Ca_In = Ca_mgL_In / AWCa / 1000;
  const Na_InA = Na_mgL_In / AWNa / 1000;
  const Alk_In = Alk_mgL_In / MWAlk / 1000;

  // Reactor quantity
  const Qty_reactor_suggested = reactorModel ? calcReactorQty(reactorModel) : null;

  function calcReactorQty(reactor: ReactorModel) {
    switch (reactor.family) {
      case 'Pearl':
        if (!reactor.pearl_reactorDesignLoad_KgOfP_Day) return null;
        return Math.ceil(P_Loading_Rate_In / reactor.pearl_reactorDesignLoad_KgOfP_Day);
      case 'PearlFx':
        if (!reactor.pearlFx_nominalFlow_M3_Day) return null;
        return Math.ceil(Q_In_M3_D / reactor.pearlFx_nominalFlow_M3_Day);
      default:
        throw new UnreachableCaseError(reactor);
    }
  }

  const Qty_reactor = Qty_reactor_user ?? Qty_reactor_suggested ?? 0;

  //  Mg
  const ro_MgCl2_Neat = 9.03 * ww_MgCl2_Neat * 100 + 992.89; //  (J34) Density of Neat MgCl2 Solution [g/L]
  const Mg_MgCl2_Neat = ww_MgCl2_Neat * ro_MgCl2_Neat * (AWMg / MWMgCl2) * 1000; //  (H39) Neat MgCl2 Solution Concentration [mg/L as Mg]
  const Q_MgCl2_Neat =
    (Q_In * ((P_mgL_In / AWP) * MgP_A - Mg_mgL_In / AWMg)) / (Mg_MgCl2_Neat / AWMg); //  (F16) Neat MgCl2 Dosing Rate [L/min]
  const Mg_mgL_InA = (Q_1 * Mg_1 + Q_2 * Mg_2 + Q_MgCl2_Neat * Mg_MgCl2_Neat) / Q_In; //  (G6) Mg mg/L
  const Q_MgCl2_Neat_reactor = Q_MgCl2_Neat / Qty_reactor; // (F16) Neat MgCl2 Dosing Rate [L/min] (MARCH2021) this is /reactor value

  const kgd_P_reactor = kgd_P / Qty_reactor; // Total P Loading Rate Per Reactor [kgP/day]

  // Temporary Flow based on Feed Stream Selection in D53. This could be = Q1 or Q2 or 0 L/min depending on selection.
  // (D53 Selection) HLOOKUP(D53,$D$22:$F$23,2, FALSE) in D71
  // const Q = feedStreamForDosing === 'feed1' ? Q_1 : feedStreamForDosing === 'feed2' ? Q_2 : 0;
  const Q = 0; // JUNE 2021: Not used as of this date

  const Acid_kmolHLSU_suggested = 2.76 * 10 ** -5; // (K54) Acid Use [kmol H+/L feed/SU]

  // Targets
  // Target Effluent P - Input as P Removal or as Effluent P - Calculations are setup as Input Effluent P
  const P_R_suggested = 40;
  const P_R = P_R_user ?? P_R_suggested; //  Effluent P Target (NEW) (used to calculated P Removal) [mg/L]
  const Rem_P = solveTarget === 'Rem_P' ? 1 - P_R / P_mgL_In : 0.8; // Target Reactor P Removal [% w/w]. (NEW) Default Value = 0.8 (i.e. 80%)
  const pH_R_suggested = 7;
  const pH_R = solveTarget === 'pH_R' ? pH_R_user ?? pH_R_suggested : 7; // For "Process Model" (NEW) Changed Variable Name from pH_In to pH_R. Default Value = 7.

  // Misc
  const Days_Ops_suggested = 365;
  const Days_Ops = Days_Ops_user ?? Days_Ops_suggested;

  // N DosingMg
  const NP = N_mgL_In / AWN / (P_mgL_In / AWP); // (Pearl Inputs D44) actual N:P Ratio [mol N/mol P]

  /// -- Additional
  const TSS_1_suggested = 1000;
  const TSS_1 = TSS_1_user ?? 1000 ?? 0;
  const TSS_2_suggested = 1000;
  const TSS_2 = TSS_2_user ?? TSS_2_suggested ?? 0;
  const TSS_mgL_In = (Q_1 * TSS_1 + Q_2 * TSS_2) / Q_In;
  /// --

  ///-- Needed by the UI only
  // Pearl reactor suggestion
  const pearlReactors = reactors
    .filter(
      (r): r is ReactorModel & { family: 'Pearl'; pearl_reactorDesignLoad_KgOfP_Day: number } =>
        r.family === 'Pearl' && Number.isFinite(r.pearl_reactorDesignLoad_KgOfP_Day),
    )
    .sort((a, b) => a.pearl_reactorDesignLoad_KgOfP_Day - b.pearl_reactorDesignLoad_KgOfP_Day);

  const suggestedPearlReactor =
    pearlReactors.length > 0
      ? pearlReactors.find(
          (r) =>
            P_Loading_Rate_In <=
            r.pearl_reactorDesignLoad_KgOfP_Day * (r.pearl_suggestionQtyThreshold ?? 1),
        ) ?? pearlReactors[pearlReactors.length - 1]
      : null;

  const Pearl_Reactor_Suggestion = suggestedPearlReactor?.name ?? null;

  const Qty_Pearl_Reactor_Suggestion = suggestedPearlReactor
    ? calcReactorQty(suggestedPearlReactor)
    : null;

  const Usage_Pearl_Reactor_Suggestion = suggestedPearlReactor
    ? calcReactorUsage(suggestedPearlReactor, Qty_Pearl_Reactor_Suggestion!)
    : null;

  // FX reactor suggestion
  const fxReactors = reactors
    .filter(
      (r): r is ReactorModel & { pearlFx_nominalFlow_M3_Day: number } =>
        r.family === 'PearlFx' && Number.isFinite(r.pearlFx_nominalFlow_M3_Day),
    )
    .sort((a, b) => a.pearlFx_nominalFlow_M3_Day - b.pearlFx_nominalFlow_M3_Day);

  const suggestedFxReactor =
    fxReactors.length > 0
      ? fxReactors.find((r) => Q_In_M3_D < r.pearlFx_nominalFlow_M3_Day) ??
        fxReactors[fxReactors.length - 1]
      : null;

  const Pearl_Fx_Reactor_Suggestion = suggestedFxReactor?.name ?? null;

  const Qty_Pearl_Fx_Reactor_Suggestion = suggestedFxReactor
    ? calcReactorQty(suggestedFxReactor)
    : null;

  const Usage_Pearl_Fx_Reactor_Suggestion = suggestedFxReactor
    ? calcReactorUsage(suggestedFxReactor, Qty_Pearl_Fx_Reactor_Suggestion!)
    : null;

  // selected reactor usage
  const Usage_reactor =
    reactorModel && Qty_reactor > 0 ? calcReactorUsage(reactorModel, Qty_reactor) : null;

  function calcReactorUsage(reactor: ReactorModel, qty: number) {
    switch (reactor.family) {
      case 'Pearl':
        if (!reactor.pearl_reactorDesignLoad_KgOfP_Day) return null;
        return P_Loading_Rate_In / (reactor.pearl_reactorDesignLoad_KgOfP_Day * qty);
      case 'PearlFx':
        if (!reactor.pearlFx_nominalFlow_M3_Day) return null;
        return Q_In_M3_D / (reactor.pearlFx_nominalFlow_M3_Day * qty);
      default:
        throw new UnreachableCaseError(reactor);
    }
  }

  ///--

  return {
    ...input,

    // Feed
    Mg_1,
    Ca_1,
    Na_1,
    Alk_1,
    pH_1,
    Cond_1,
    Mg_2,
    Ca_2,
    Na_2,
    Alk_2,
    pH_2,
    Cond_2,
    Mg_1_suggested,
    Ca_1_suggested,
    Na_1_suggested,
    Alk_1_suggested,
    pH_1_suggested,
    Cond_1_suggested,
    Mg_2_suggested,
    Ca_2_suggested,
    Na_2_suggested,
    Alk_2_suggested,
    pH_2_suggested,
    Cond_2_suggested,

    // Combined feeds
    Q_In,
    Mg_mgL_In,
    N_mgL_In,
    P_mgL_In,
    Ca_mgL_In,
    Na_mgL_In,
    Alk_mgL_In,
    Cond_In,

    // Chemical data
    Q_MgCl2_Neat,
    Mg_MgCl2_Neat,
    Mg_mgL_InA,
    Q_MgCl2_Neat_reactor,

    // combined influent items
    pH_In,
    MgP_In,

    // 'Process Model' - Recipe Data Inputs - Calculations & Lookups
    kgd_P,
    kgd_P_reactor,

    // reactor data
    MgP_D,
    Qty_reactor,

    // Outputs
    Mg_InA,
    N_InA,
    P_In,
    Ca_In,
    Na_InA,
    Alk_In,

    // caustic use
    Mg_NaOH_Offset,

    // acid stream
    Acid_kmolHLSU_suggested,
    Acid_kmolHLSU: Acid_kmolHLSU_user ?? Acid_kmolHLSU_suggested,
    Q,

    // targets
    P_R_suggested,
    Rem_P,
    pH_R_suggested,
    pH_R,

    // misc
    Days_Ops_suggested,
    Days_Ops,

    // N dosing
    NP,
    NP_D_suggested,
    NP_D: NP_D_user ?? NP_D_suggested ?? 0,

    // Needed by the UI only
    P_Loading_Rate_1,
    P_Loading_Rate_2,
    P_Loading_Rate_In,

    Pearl_Reactor_Suggestion,
    Qty_Pearl_Reactor_Suggestion,
    Usage_Pearl_Reactor_Suggestion,

    Pearl_Fx_Reactor_Suggestion,
    Qty_Pearl_Fx_Reactor_Suggestion,
    Usage_Pearl_Fx_Reactor_Suggestion,
    Pearl_Fx_Max_Concentration,

    Qty_reactor_suggested,
    Usage_reactor,

    // Additional
    TSS_1_suggested,
    TSS_1,
    TSS_2_suggested,
    TSS_2,
    TSS_mgL_In,
  };
}

function suggestedFeedValues(type: FlowType) {
  switch (type) {
    case FlowType.EMPTY:
      return {
        Mg: 0,
        Ca: 0,
        Na: 0,
        Alk: 0,
        pH: 0,
        Cond: 0,
      };
    case FlowType.OTHER:
      return {
        Mg: null,
        Ca: null,
        Na: null,
        Alk: null,
        pH: null,
        Cond: null,
      };
    case FlowType.POST_DIGESTION:
      return {
        Mg: 10,
        Ca: 50,
        Na: 100,
        Alk: 2800,
        pH: 7.5,
        Cond: 7000,
      };
    case FlowType.WASSTRIP:
      return {
        Mg: 40,
        Ca: 50,
        Na: 100,
        Alk: 500,
        pH: 6.5,
        Cond: 2000,
      };
    default:
      throw new UnreachableCaseError(type);
  }
}

export interface SecondaryModelComputedInput {
  Operating_Labour_Cost: number;
  Operating_Labour_Cost_suggested: number | null;
  Electricity_Cost: number;
  Electricity_Cost_suggested: number | null;
  currency: string;
  currency_suggested: string;
  MetalSalt_Cost: number;
  MetalSalt_Cost_suggested: number | null;
  MetalSalt_Unit: string;
  MetalSalt_Solution_Concentration: number;
  MetalSalt_Solution_Concentration_suggested: number | null;
  Metal_P_Ratio: number;
  Metal_P_Ratio_suggested: number | null;
  Alk_Consumption_Required: string;
  NaOH_Operating_Unit: string;
  NaOH_Operating_Cost: number;
  NaOH_Operating_Cost_suggested: number | null;
  Polymer_Use: number;
  Polymer_Use_suggested: number | null;
  Polymer_Cost: number;
  Polymer_Cost_suggested: number | null;
  Sludge_Disposal_Cost: number;
  Sludge_Disposal_Cost_suggested: number | null;
  Cake_Solids: number;
  Cake_Solids_suggested: number | null;
  Plant_Sludge_Production: number;
  Plant_Sludge_Production_suggested: number | null;
  WASSTRIP_Cake_Solids_Improvement: number;
  WASSTRIP_Cake_Solids_Improvement_suggested: number | null;
  WASSTRIP_Polymer_Reduction: number;
  WASSTRIP_Polymer_Reduction_suggested: number | null;
  Ammonia_Removal_Cost: number;
  Ammonia_Removal_Cost_suggested: number | null;
  Crystal_Green_Offtake_Price: number;
  Crystal_Green_Offtake_Price_suggested: number | null;
  Dryer_Type: string;
  Qty_dryer: number;
  Qty_dryer_suggested: number | null;
  Natural_Gas_Cost: number;
  Natural_Gas_Cost_suggested: number | null;
  Hot_Water_Cost: number;
  Hot_Water_Cost_suggested: number | null;
  Mg_Operating_Cost: number;
  Mg_Operating_Cost_suggested: number | null;
  Mg_Operating_Unit: string;
  Mg_Operating_Unit_suggested: string;
  Ammonia_Cost: number;
  Ammonia_Cost_suggested: number | null;
  Acid_Cost: number;
  Acid_Cost_suggested: number | null;
  Lab_Hatch_Cost: number;
  Lab_Hatch_Cost_suggested: number | null;
  Pallets_Unit_Cost: number;
  Pallets_Unit_Cost_suggested: number | null;
  Bags_Unit_Cost: number;
  Bags_Unit_Cost_suggested: number | null;
}

export function processSecondaryComputedInputOf(
  constants: RequiredProcessModelConstants,
  input: UserNormalizedInput,
): SecondaryModelComputedInput {
  const {
    Operating_Labour_Cost_suggested = null,
    Electricity_Cost_suggested = null,
    MetalSalt_Cost_suggested = null,
    MetalSalt_Solution_Concentration_suggested = null,
    Metal_P_Ratio_suggested = null,
    NaOH_Operating_Cost_suggested = null,
    Polymer_Use_suggested = null,
    Polymer_Cost_suggested = null,
    Sludge_Disposal_Cost_suggested = null,
    Cake_Solids_suggested = null,
    Ammonia_Removal_Cost_suggested = null,
    Crystal_Green_Offtake_Price_suggested = null,
    Qty_dryer_suggested = null,
    Natural_Gas_Cost_suggested = null,
    Hot_Water_Cost_suggested = null,
    Mg_Operating_Cost_suggested = null,
    Acid_Cost_suggested = null,
    Lab_Hatch_Cost_suggested = null,
  } = constants;

  const isBulkOffloading = input.Product_Handling_Requested === 'Bulk Offloading';

  const Pallets_Unit_Cost_suggested = isBulkOffloading
    ? 0
    : constants.Pallets_Unit_Cost_suggested ?? null;

  const Bags_Unit_Cost_suggested = isBulkOffloading
    ? 0
    : constants.Bags_Unit_Cost_suggested ?? null;

  const isAmmoniaNotUsed = input.ammonia?.id === 'not_used';

  const Ammonia_Cost_suggested = isAmmoniaNotUsed ? 0 : constants.Ammonia_Cost_suggested ?? null;

  const currency_suggested = '$';

  const usingWasstripStream =
    input.flowType_1 === FlowType.WASSTRIP || input.flowType_2 === FlowType.WASSTRIP;

  const WASSTRIP_Cake_Solids_Improvement_suggested = usingWasstripStream
    ? constants.WASSTRIP_Cake_Solids_Improvement_suggested ?? null
    : 0;

  const WASSTRIP_Polymer_Reduction_suggested = usingWasstripStream
    ? constants.WASSTRIP_Polymer_Reduction_suggested ?? null
    : 0;

  const dryTonNormalized = input.unitSystem === 'metric' ? 'dry tonne' : 'dry ton';

  const Mg_Operating_Unit_suggested = dryTonNormalized;

  // Default to 0 if there are two post digestion streams, as its likely an invalid configuration
  const postDigestionStreamFlow =
    input.flowType_1 === FlowType.POST_DIGESTION
      ? input.flowType_2 !== FlowType.POST_DIGESTION
        ? input.Q_1
        : 0
      : input.flowType_2 === FlowType.POST_DIGESTION
      ? input.Q_2
      : 0;

  const lMinToGalDay = Qty.swiftConverter('L/min', `gal/d`);

  /*
   Matt Kuzma:
   The default calculation for the “Plant Sludge Production (dry ton/day)” field is to take the “Post Digestion Stream”
   flow rate (in MGD = millions of gallons per day, so will have to convert metric to imperial here), multiply by 200,
   then multiply by 0.6…so the math would be “Post Digestion Stream Flow” (given in GPD) divided by 1,000,000
   (converting GPD to MGD), multiply by 200, and then multiply by 0.6 = dry tons solids/day of Plant Sludge Production”.
   If there is no “Post Digestion Stream”, have it default to 0.
   */
  const sludgeProductionDryTons = (lMinToGalDay(postDigestionStreamFlow) / 1_000_000) * 200 * 0.6;

  const Plant_Sludge_Production_suggested =
    input.unitSystem === 'imperial'
      ? sludgeProductionDryTons
      : Qty.swiftConverter('ton/d', `tonne/d`)(sludgeProductionDryTons);

  return {
    Operating_Labour_Cost: input.Operating_Labour_Cost ?? Operating_Labour_Cost_suggested ?? 0,
    Operating_Labour_Cost_suggested,
    Electricity_Cost: input.Electricity_Cost ?? Electricity_Cost_suggested ?? 0,
    Electricity_Cost_suggested,
    currency: input.currency ?? currency_suggested,
    currency_suggested,
    MetalSalt_Cost: input.MetalSalt_Cost ?? MetalSalt_Cost_suggested ?? 0,
    MetalSalt_Cost_suggested,
    MetalSalt_Unit: input.MetalSalt_Unit,
    MetalSalt_Solution_Concentration:
      input.MetalSalt_Solution_Concentration ?? MetalSalt_Solution_Concentration_suggested ?? 0,
    MetalSalt_Solution_Concentration_suggested,
    Metal_P_Ratio: input.Metal_P_Ratio ?? Metal_P_Ratio_suggested ?? 0,
    Metal_P_Ratio_suggested,
    Alk_Consumption_Required: input.Alk_Consumption_Required,
    NaOH_Operating_Unit: input.NaOH_Operating_Unit,
    NaOH_Operating_Cost: input.NaOH_Operating_Cost ?? NaOH_Operating_Cost_suggested ?? 0,
    NaOH_Operating_Cost_suggested,
    Polymer_Use: input.Polymer_Use ?? Polymer_Use_suggested ?? 0,
    Polymer_Use_suggested,
    Polymer_Cost: input.Polymer_Cost ?? Polymer_Cost_suggested ?? 0,
    Polymer_Cost_suggested,
    Sludge_Disposal_Cost: input.Sludge_Disposal_Cost ?? Sludge_Disposal_Cost_suggested ?? 0,
    Sludge_Disposal_Cost_suggested,
    Cake_Solids: input.Cake_Solids ?? Cake_Solids_suggested ?? 0,
    Cake_Solids_suggested,
    Plant_Sludge_Production:
      input.Plant_Sludge_Production ?? Plant_Sludge_Production_suggested ?? 0,
    Plant_Sludge_Production_suggested,
    WASSTRIP_Cake_Solids_Improvement:
      input.WASSTRIP_Cake_Solids_Improvement ?? WASSTRIP_Cake_Solids_Improvement_suggested ?? 0,
    WASSTRIP_Cake_Solids_Improvement_suggested,
    WASSTRIP_Polymer_Reduction:
      input.WASSTRIP_Polymer_Reduction ?? WASSTRIP_Polymer_Reduction_suggested ?? 0,
    WASSTRIP_Polymer_Reduction_suggested,
    Ammonia_Removal_Cost: input.Ammonia_Removal_Cost ?? Ammonia_Removal_Cost_suggested ?? 0,
    Ammonia_Removal_Cost_suggested,
    Crystal_Green_Offtake_Price:
      input.Crystal_Green_Offtake_Price ?? Crystal_Green_Offtake_Price_suggested ?? 0,
    Crystal_Green_Offtake_Price_suggested,
    Dryer_Type: input.Dryer_Type,
    Qty_dryer: input.Qty_dryer ?? Qty_dryer_suggested ?? 0,
    Qty_dryer_suggested,
    Natural_Gas_Cost: input.Natural_Gas_Cost ?? Natural_Gas_Cost_suggested ?? 0,
    Natural_Gas_Cost_suggested,
    Hot_Water_Cost: input.Hot_Water_Cost ?? Hot_Water_Cost_suggested ?? 0,
    Hot_Water_Cost_suggested,
    Mg_Operating_Cost: input.Mg_Operating_Cost ?? Mg_Operating_Cost_suggested ?? 0,
    Mg_Operating_Cost_suggested,
    Mg_Operating_Unit: input.Mg_Operating_Unit ?? Mg_Operating_Unit_suggested,
    Mg_Operating_Unit_suggested,
    Ammonia_Cost: input.Ammonia_Cost ?? Ammonia_Cost_suggested ?? 0,
    Ammonia_Cost_suggested,
    Acid_Cost: input.Acid_Cost ?? Acid_Cost_suggested ?? 0,
    Acid_Cost_suggested,
    Lab_Hatch_Cost: input.Lab_Hatch_Cost ?? Lab_Hatch_Cost_suggested ?? 0,
    Lab_Hatch_Cost_suggested,
    Pallets_Unit_Cost: input.Pallets_Unit_Cost ?? Pallets_Unit_Cost_suggested ?? 0,
    Pallets_Unit_Cost_suggested,
    Bags_Unit_Cost: input.Bags_Unit_Cost ?? Bags_Unit_Cost_suggested ?? 0,
    Bags_Unit_Cost_suggested,
  };
}
