import React, { FC } from 'react';
import { Typography } from '@material-ui/core';

export const CalculatedValue: FC = ({ children }) => <Typography>{children}</Typography>;

export const CalculatedNumber: FC<{ value: number | null }> = ({ value }) => (
  <CalculatedValue>{fmtNumber(value)}</CalculatedValue>
);

export const CalculatedNumberLabel: FC<{ value: number | null; label: string; unit?: string }> = ({
  label,
  value,
  unit,
}) => (
  <CalculatedValue>
    <small>{label}</small> <br />
    {fmtNumber(value, unit)}
  </CalculatedValue>
);

export const CalculatedPercentage: FC<{ value: number | null }> = ({ value }) => (
  <CalculatedValue>{fmtPercentage(value)}</CalculatedValue>
);

export const CalculatedPercentageLabel: FC<{
  value: number | null;
  label: string;
}> = ({ label, value }) => (
  <CalculatedValue>
    <small>{label}</small> <br />
    {fmtPercentage(value)}
  </CalculatedValue>
);

export function fmtNumber(v: number | null, unit?: string) {
  if (!Number.isFinite(v)) return '-';

  return v!.toLocaleString('en-US', { maximumFractionDigits: 2 }) + (unit ? ' ' + unit : '');
}

export function fmtPercentage(v: number | null, fallback = '-') {
  if (!Number.isFinite(v)) return fallback;

  return v!.toLocaleString('en-US', { style: 'percent', maximumFractionDigits: 2 });
}
