import { useSalesToolQuery } from '../lib/sales-tool-api';
import { useCallback, useMemo } from 'react';
import { useListUsers } from '../lib/admin-queries';
import { User } from './User';

const useUsers = () => {
  const {
    data: dbUsers,
    isLoading: dbLoading,
    error: dbError,
  } = useSalesToolQuery<User[]>('users', '/users');
  const { data: cognitoUsers, isLoading: cognitoLoading, error: cognitoError } = useListUsers();

  const users = useMemo(() => {
    if (dbUsers && cognitoUsers) {
      const cogUsers = cognitoUsers.Users;
      dbUsers.forEach((dbUser) => {
        const cogUser = cogUsers?.find((cogUser) => cogUser.Username === dbUser.awsCognitoUsername);
        if (cogUser) {
          const userAttrs = cogUser.Attributes;
          const name = userAttrs?.find((a) => a.Name === 'name')?.Value;
          const lastNameObj = userAttrs?.find((a) => a.Name === 'middle_name');
          const lastName = lastNameObj?.Value || '';
          dbUser.name = `${name} ${lastName}`.trim();
        } else {
          dbUser.name = dbUser.awsCognitoUsername;
        }
      });

      return dbUsers;
    } else return [];
  }, [dbUsers, cognitoUsers]);

  const getUserById = useCallback(
    (userId: string) => {
      return users.find((u) => u.id.toString() === userId.toString());
    },
    [users],
  );

  return {
    error: dbError || cognitoError,
    isLoading: dbLoading || cognitoLoading,
    users,
    getUserById,
  };
};

export default useUsers;
