import React, { FC, useEffect, useReducer } from 'react';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { onAuthUIStateChange, AuthState, CognitoUserInterface } from '@aws-amplify/ui-components';
import { Route, Switch } from 'react-router';
import AdminDashboard from './components/AdminDashboard';
import SalesRepDashboard from './components/SalesRepDashboard';
import UpdatePassword from './components/UpdatePassword';
import authReducer, { AUTH_TYPES_REDUCER, initAuthReducer } from './reducers/auth-reducer';
import AuthContextProvider from './components/AuthContext';
import Layout from './base/Layout';
import { CircularProgress, Grid } from '@material-ui/core';
import ProcessModelRuns from './components/ProcessModelRuns';
import { salesToolApiFetch } from './lib/sales-tool-api';
import logo from './assets/logo-vertical.png';
import isCurrentUserAdmin from './lib/user-utils';
import Link from '@material-ui/core/Link';
import { Auth } from 'aws-amplify';
import { IDENTITY_PROVIDER } from './index';

const SalesTool: FC<{ amplifyTheming: object }> = ({ amplifyTheming }) => {
  const [{ loading, user, isAdmin, showFederatedLogin }, dispatch] = useReducer(
    authReducer,
    initAuthReducer,
  );

  useEffect(() => {
    const unSubscribe = onAuthUIStateChange((nextAuthState, authData: any) => {
      // Registering user here to avoid issues on creation of PMR(assignation of user, createdById)
      if (nextAuthState === AuthState.SignedIn) {
        try {
          // Doing really nothing after registering the user
          salesToolApiFetch('/users', {
            method: 'POST',
            body: new URLSearchParams({
              awsCognitoUsername: authData.username,
            }),
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          });
        } catch (e) {
          // Move to toast ?
          console.error('There was a error registering the user: ', e);
        }
      }
      // End of block to move
      dispatch({
        type: AUTH_TYPES_REDUCER.UPDATE_AUTH_STATE,
        nextAuthState,
        authData: authData as CognitoUserInterface,
        isAdmin,
      });
    });

    return unSubscribe;
  }, [isAdmin]);

  useEffect(() => {
    let isSubscribed = true;

    if (user && isSubscribed) {
      const checkIfAdmin = async () => {
        const isUserAdmin = await isCurrentUserAdmin();
        dispatch({ type: AUTH_TYPES_REDUCER.SET_IS_ADMIN, isAdmin: isUserAdmin });
      };
      checkIfAdmin();
    }

    return () => {
      isSubscribed = false;
    };
  }, [user]);

  return loading ? (
    <Grid container justify="center" style={{ marginTop: 80 }}>
      <CircularProgress disableShrink />
    </Grid>
  ) : user ? (
    <AuthContextProvider user={user} isAdmin={isAdmin}>
      <Layout isAdmin={isAdmin}>
        <Switch>
          <Route exact path="/update-password" component={UpdatePassword} />
          <Route exact path="/processModelRuns" component={ProcessModelRuns} />
          <Route path="/" component={isAdmin ? AdminDashboard : SalesRepDashboard} />
        </Switch>
      </Layout>
    </AuthContextProvider>
  ) : (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      wrap="nowrap"
      style={{ height: '100vh' }}
    >
      <img src={logo} alt="logo" style={{ maxWidth: 150, marginBottom: 40 }} />
      <AmplifyAuthenticator style={amplifyTheming}>
        <AmplifySignIn hideSignUp slot="sign-in">
          <div slot="federated-buttons" />
        </AmplifySignIn>
      </AmplifyAuthenticator>

      {showFederatedLogin && (
        <Link
          href="#"
          onClick={() => Auth.federatedSignIn({ customProvider: IDENTITY_PROVIDER })}
          color="secondary"
          style={{ marginTop: '20px' }}
        >
          Login with Office365
        </Link>
      )}
    </Grid>
  );
};

export default SalesTool;
