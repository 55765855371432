import { createStyles, InputBase, Theme, withStyles } from '@material-ui/core';
import { amplifyLightGrey, primaryColor } from './theme';

const STInputBase = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
      marginBottom: theme.spacing(2),
    },
    input: {
      fontSize: 14,
      borderRadius: 3,
      position: 'relative',
      fontFamily: theme.typography.fontFamily,
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${amplifyLightGrey}`,
      padding: '17px 26px 14px 14px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus, &:hover': {
        borderRadius: 3,
        borderColor: primaryColor,
      },
      '&:focus': {
        borderWidth: 2,
        padding: '16px 25px 13px 13px',
      },
    },
  }),
)(InputBase);

export default STInputBase;
