import React, { FC } from 'react';
import { Fieldset } from '../Fieldset';
import { Grid } from '@material-ui/core';
import { FormTextInput } from './Inputs';
import { ReactorFeedDataFields } from './ReactorFeedDataFields';
import { ReactorModelFields } from './ReactorModelFields';
import { ReactorDataFields } from './ReactorDataFIelds';
import { ChemicalDataFields } from './ChemicalDataFields';
import { TargetFields } from './TargetFields';
import {
  AmmoniaType,
  MagnesiumType,
  ProcessModelComputedInput,
  RawUserInput,
  ReactorModel,
} from './userInput';

interface MainFormFieldProps {
  rawInput: RawUserInput;
  updateRawInput: <T extends keyof RawUserInput>(key: T, value: RawUserInput[T]) => void;
  computedInput: ProcessModelComputedInput;
  reactors: ReactorModel[];
  mgTypes: MagnesiumType[];
  ammoniaTypes: AmmoniaType[];
}

const MainFormFields: FC<MainFormFieldProps> = ({
  rawInput,
  updateRawInput: set,
  computedInput: computed,
  reactors,
  mgTypes,
  ammoniaTypes,
}) => {
  return (
    <>
      <Fieldset title="General Information">
        <Grid item xs={12} sm={6}>
          <FormTextInput
            label="Plant Name"
            value={rawInput.plantName ?? ''}
            onChange={(ev) => set('plantName', ev.target.value)}
          />
        </Grid>

        <Grid item xs>
          <FormTextInput
            label="Plant Owner"
            value={rawInput.plantOwner ?? ''}
            onChange={(ev) => set('plantOwner', ev.target.value)}
          />
        </Grid>
      </Fieldset>
      <ReactorFeedDataFields
        set={set}
        flowType_1={rawInput.flowType_1}
        flowType_2={rawInput.flowType_2}
        flowLabel_1={rawInput.flowLabel_1}
        flowLabel_2={rawInput.flowLabel_2}
        flowUnitWeb={rawInput.flowUnitWeb}
        Q_1={rawInput.Q_1}
        Q_2={rawInput.Q_2}
        Q_In={computed.Q_In}
        P_1={rawInput.P_1}
        P_2={rawInput.P_2}
        P_Loading_Rate_1={computed.P_Loading_Rate_1}
        P_Loading_Rate_2={computed.P_Loading_Rate_2}
        P_Loading_Rate_In={computed.P_Loading_Rate_In}
        P_mgL_In={computed.P_mgL_In}
        N_1={rawInput.N_1}
        N_2={rawInput.N_2}
        N_mgL_In={computed.N_mgL_In}
        Mg_1_user={rawInput.Mg_1_user}
        Mg_1_suggested={computed.Mg_1_suggested}
        Mg_2_user={rawInput.Mg_2_user}
        Mg_2_suggested={computed.Mg_2_suggested}
        Mg_mgL_In={computed.Mg_mgL_In}
        Ca_1_user={rawInput.Ca_1_user}
        Ca_1_suggested={computed.Ca_1_suggested}
        Ca_2_user={rawInput.Ca_2_user}
        Ca_2_suggested={computed.Ca_2_suggested}
        Ca_mgL_In={computed.Ca_mgL_In}
        Na_1_user={rawInput.Na_1_user}
        Na_1_suggested={computed.Na_1_suggested}
        Na_2_user={rawInput.Na_2_user}
        Na_2_suggested={computed.Na_2_suggested}
        Na_mgL_In={computed.Na_mgL_In}
        pH_1_user={rawInput.pH_1_user}
        pH_1_suggested={computed.pH_1_suggested}
        pH_2_user={rawInput.pH_2_user}
        pH_2_suggested={computed.pH_2_suggested}
        pH_In={computed.pH_In}
        Alk_1_user={rawInput.Alk_1_user}
        Alk_1_suggested={computed.Alk_1_suggested}
        Alk_2_user={rawInput.Alk_2_user}
        Alk_2_suggested={computed.Alk_2_suggested}
        Alk_mgL_In={computed.Alk_mgL_In}
        Cond_1_user={rawInput.Cond_1_user}
        Cond_1_suggested={computed.Cond_1_suggested}
        Cond_2_user={rawInput.Cond_2_user}
        Cond_2_suggested={computed.Cond_2_suggested}
        Cond_In={computed.Cond_In}
        TSS_1_user={rawInput.TSS_1_user}
        TSS_2_user={rawInput.TSS_2_user}
        TSS_1_suggested={computed.TSS_1_suggested}
        TSS_2_suggested={computed.TSS_2_suggested}
        TSS_mgL_In={computed.TSS_mgL_In}
      />
      <ReactorModelFields
        set={set}
        Pearl_Reactor_Suggestion={computed.Pearl_Reactor_Suggestion}
        Qty_Pearl_Reactor_Suggestion={computed.Qty_Pearl_Reactor_Suggestion}
        Usage_Pearl_Reactor_Suggestion={computed.Usage_Pearl_Reactor_Suggestion}
        Pearl_Fx_Reactor_Suggestion={computed.Pearl_Fx_Reactor_Suggestion}
        Qty_Pearl_Fx_Reactor_Suggestion={computed.Qty_Pearl_Fx_Reactor_Suggestion}
        Usage_Pearl_Fx_Reactor_Suggestion={computed.Usage_Pearl_Fx_Reactor_Suggestion}
        reactorModel={rawInput.reactorModel}
        Qty_reactor_user={rawInput.Qty_reactor_user}
        Qty_reactor_suggested={computed.Qty_reactor_suggested}
        Usage_reactor={computed.Usage_reactor}
        P_mgL_In={computed.P_mgL_In}
        Pearl_Fx_Max_Concentration={computed.Pearl_Fx_Max_Concentration}
        reactorModels={reactors}
      />
      <ReactorDataFields
        set={set}
        MgP_A={rawInput.MgP_A}
        Days_Ops_user={rawInput.Days_Ops_user}
        NP_Add_YN={rawInput.NP_Add_YN}
        NP_D_user={rawInput.NP_D_user}
        Days_Ops_suggested={computed.Days_Ops_suggested}
        NP={computed.NP}
        NP_D_suggested={computed.NP_D_suggested}
      />
      <ChemicalDataFields
        set={set}
        ww_MgCl2_Neat={rawInput.ww_MgCl2_Neat}
        ww_NaOH_Neat={rawInput.ww_NaOH_Neat}
        mgType={rawInput.mgType}
        mgTypes={mgTypes}
        ammonia={rawInput.ammonia}
        NP_Add_YN={rawInput.NP_Add_YN}
        ammoniaTypes={ammoniaTypes}
      />
      <TargetFields
        set={set}
        solveTarget={rawInput.solveTarget}
        P_R_user={rawInput.P_R_user}
        pH_R_user={rawInput.pH_R_user}
        P_R_suggested={computed.P_R_suggested}
        pH_R_suggested={computed.pH_R_suggested}
      />
    </>
  );
};

export default MainFormFields;
