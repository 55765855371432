import React, { FC } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import uniqueId from 'lodash/uniqueId';

const STDatePicker: FC<KeyboardDatePickerProps> = ({ label, value, onChange, ...props }) => {
  const uniqDatepicker = uniqueId('datepicker-');

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        id={uniqDatepicker}
        disableFuture
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format="MM/dd/yyyy"
        label={label}
        value={value}
        onChange={onChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

export default STDatePicker;
